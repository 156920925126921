
import { Component, Prop, Vue } from "vue-property-decorator";
import VCombobox from "vuetify";
import { Utils } from "@/Utility";
import { SeasonName } from "@/models/Season";
import { TeamName } from "@/models/Team";
import { AlenaName } from "@/models/Alena";
import store from "@/store";
import { UpdateProfileParams, ForcedAcceptCampaign } from "@/store/modules/lead";
import { Lead } from "@/models/Lead";
import { CampaignHistory } from '@/models/Campaign';

type EmailSendable = {
  stat: boolean,
  name: string
}

@Component({
  components: {},
})
export default class CampaignApplicationHistoryDialog extends Vue {
  @Prop({ default: false })
  show!: boolean;

  @Prop({ default: 500 })
  width!: number;

  @Prop({
    type: Array,
    default: Array(0),
    required: true,
  })
  campaignHistory!: Array<CampaignHistory>;
  @Prop({
    type: Object,
    default: undefined,
    required: true,
  })
  details!: Lead;

  forcedAccept = new Map<string, boolean>()
  
  get tableHeader() {
    return [
      { text: "キャンペーンシーズン", value: "season_name", width: "auto" },
      { text: "キャンペーン申込日時", value: "campaign_application_at", width: "auto" },
      { text: "申込キャンペーン名", value: "campaign_name", width: "auto" },
      {
        text: "キャンペーン当選結果",
        value: "campaign_status_for_display",
        width: "auto",
      },
      { text: "強制当選", value: "is_forced_accept", width: "auto" },
    ];
  }


  decrypt(word: string){
    return Utils.decrypt(word)
  }


  
  commit() {
    console.log(this.forcedAccept)
    
    
    const campaignList = this.campaignHistory.filter((ch)=>ch.is_forced_accept != this.forcedAccept.get(ch.campaign_id))
                        .map((ch)=>{
                          return {
                            campaign_id: ch.campaign_id,
                            is_forced_accept: this.forcedAccept.get(ch.campaign_id)
                          } as ForcedAcceptCampaign
                        });
    if(campaignList.length > 0){        
      store.dispatch('lead/forcedAccept', {
          email: this.details.email,
          campaign_list: campaignList,
        }).then((res)=>{
          this.$emit('onUpdate')
        });
    }else{
          this.$emit('onUpdate')
    }
                


    // store
    //   .dispatch("lead/updateProfile", {
    //     email: this.details.email,
    //     is_black_list: this.isBlacklist,
    //     is_send_mail: this.emailSendStatus!.stat
    //   } as UpdateProfileParams )
    //   .then((res) => {
    //     res;
    //     this.$emit("onUpdate");
    //   });
  }
  created() {
    console.log("mounted");
    
    for(var i = 0;i < this.campaignHistory.length;i++){
      this.forcedAccept.set(
        this.campaignHistory[i].campaign_id, 
        this.campaignHistory[i].is_forced_accept)
        console.log(this.getInitialValue(this.campaignHistory[i].campaign_id))
    }
    console.log(this.forcedAccept)
  }

  getInitialValue(campaignId: string ){
    return this.campaignHistory.find((ch)=>ch.campaign_id == campaignId)!.is_forced_accept
  }
}
