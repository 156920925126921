
import { Component, Prop, Vue } from "vue-property-decorator";
import VCombobox from "vuetify";
import { Utils } from "@/Utility";
import { SeasonName } from "@/models/Season";
import { TeamName } from "@/models/Team";
import { AlenaName } from "@/models/Alena";
import store from "@/store";
import { PutGameParams, DeleteGameParams } from "@/store/modules/game";
import { Game } from '@/models/Game'
import DeleteDialog from '@/components/DeleteDialog.vue'

class ConditionType {
  id: number;
  name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}

@Component({
  components: {
    DeleteDialog
  },
})
export default class UpdateGameDialog extends Vue {
  @Prop({ default: false })
  show!: boolean;

  @Prop({ default: 500 })
  width!: number;


  @Prop({
    type: Array,
    default: Array(0),
    required: true,
  })
  alenas!: Array<AlenaName>;

  @Prop({
    type: Array,
    default: Array(0),
    required: true,
  })
  teams!: Array<TeamName>;

  @Prop({
    type: Object,
    default: {},
    required: true
  })
  details!: Game

  public alenaName: AlenaName | null = null;
  public teamName: TeamName | null = null;
  public gameDate: string | null = null;
  public selectDate = false;
  public gameTime: string | null = null;
  public selectTime = false;

  constructor() {
    super();
  }
  formatter(d: string) {
    return new Date(d).getDate();
  }

  allowedMinutes = (m: number) => m % 5 === 0;

  get alenaRules() {
    return [(v: AlenaName) => v ?? "入力必須項目です"];
  }

  get gameDateRules() {
    return [(v: string) => v?.length > 0 || "入力必須項目です"];
  }
  get gameTimeRules() {
    return [(v: string) => v?.length > 0 || "入力必須項目です"];
  }

  get teamRules() {
    return [(v: TeamName) => v ?? "入力必須項目です"];
  }

  get gameSeasonRules() {
    return [(v: SeasonName) => v ?? "入力必須項目です"];
  }

  showDeleteDialog = false;
  remove(){

    store.dispatch('game/deleteGame', {
      game_id: this.details.game_id,
    } as DeleteGameParams).then((result)=>{
      result;
      this.$emit("onDelete")
    })
  }

  commit() {
    const ref = this.$refs.form as Vue & { validate: () => boolean };
    
    if (!ref) {
      return;
    }
    if (!ref.validate()) {
      return;
    }

    const [year, month, day] = this.gameDate!.split("-");
    store
      .dispatch("game/updateGame", {
        game_id: this.details.game_id,
        away_team_id: this.teamName!.team_id,
        alena_id: this.alenaName!.alena_id,
        game_at: `${year}/${month.padStart(2, "0")}/${day.padStart(2, "0")} ${
          this.gameTime
        }:00`,
      } as PutGameParams)
      .then((res) => {
        res;
        this.$emit("onUpdate");
      });
  }
  mounted() {
    
    const [hour,minute,second] = this.details.game_at.split(' ')[1].split(':')
    this.gameDate = this.details.game_at.split(' ')[0].replace('/', '-');
    this.gameTime = `${hour}:${minute}`
    this.alenaName = this.alenas.find((a: AlenaName)=>a.alena_id == this.details.alena_id) ?? null
    this.teamName = this.teams.find((t: TeamName)=>t.team_id == this.details.team_id) ?? null;
    this.selectDate = false;
    this.selectTime = false;
  }
}
