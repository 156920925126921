
import { SignInParams } from "@/store/modules/auth";
import store from "@/store";

import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class ForgetPasswordView extends Vue {
  get pwRules() {
    return [(v: string) => !!v || "パスワードを入力してください"];
  }

  get mailRules() {
    return [
      (v: string) => !!v || "入力必須項目です",
      (v: string) =>
        /.+@.+\..+/.test(v) || "有効なメールアドレスを入力してください",
    ];
  }

  email = "";
  mailSent = false;

  forgotPassword() {
    const ref = this.$refs.form as Vue & { validate: () => boolean };

    if (!ref) {
      return;
    }
    if (!ref.validate()) {
      store.dispatch("global/SetError", "入力項目にエラーがあります。");
      return;
    }

    store
      .dispatch("user/forgotPassword", { email: this.email })
      .then((data) => {
        data;
        this.mailSent = true;
      });
  }

  onClose(){
    this.mailSent = false;
    this.$router.push({name: 'login'})
  }

  mounted() {
    // this.$store.dispatch("globals/setNotFound", false)
  }
}
