import api from '@/services/api';
import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import store from '@/store'
import { RootState } from '@/store';

import { SignInResponse, RefreshResponse } from '@/models/ApiResponse';
import { LandingPageState } from '../types';

import { LandingPage, LandingPageName } from '@/models/LandingPage';

import { Utils } from '@/Utility';

const LandingPageApiEndpoint = {
  fetchTableList : 'api/v1/campaigns/landing_page/list',
  createLandingPage : 'api/v1/campaigns/landing_page',
  updateLandingPage : 'api/v1/campaigns/landing_page',
  deleteLandingPage : 'api/v1/campaigns/landing_page',
  fetchLandingPageNameList: 'api/v1/campaigns/landing_page/name_list'
}

// チーム一覧を取得する際のパラメータ
export type FetchLandingPageListParams = {
  // シーズンID
  season_id: string,
  campaign_id: string|null,
  landing_page_id: string|null,
}

export type PostLandingPageParams = {
  landing_page_name: string,
  landing_page_url: string,
  campaign_id: string,
  lp_publish_start_at: string,
  lp_publish_end_at: string,
}

// アリーナを更新する際のパラメータ
export type PutLandingPageParams = {
  landing_page_id: string,
  landing_page_name: string,
  landing_page_url: string,
  campaign_id: string,
  lp_publish_start_at: string,
  lp_publish_end_at: string,
}

// アリーナを削除する際のパラメータ
export type DeleteLandingPageParams = {
  landing_page_id: string,
}

// チーム一覧の返却
export type FetchLandingPageListResponse = {
  landing_page_list: Array<LandingPage>
}

export type FetchLandingPageNameListResponse = {
  landing_page_name_list: Array<LandingPageName>
}

const state: LandingPageState = {
  searchSeasonId: "",
  searchCampaignId: null,
  searchLandingPageId: null,
}

export const LandingPageActionTree: ActionTree<LandingPageState, RootState> = {
  fetchLandingPageList: async({ commit }, params: FetchLandingPageListParams) => {
    return await api.get(LandingPageApiEndpoint.fetchTableList, {
      params: Utils.trimValidData(params)
    }).then(res => {
      const fetchLandingPageListResponse = res.data.data as FetchLandingPageListResponse
      commit('saveSearchConditions', params)

      return fetchLandingPageListResponse
    });
  },
  createLandingPage: async({ commit }, params: PostLandingPageParams) => {
    return await api.post(LandingPageApiEndpoint.createLandingPage, Utils.trimUndefined(params))
    .then(() => {
      return
    });
  },
  updateLandingPage: async({ commit }, params: PutLandingPageParams) => {
    return await api.put(LandingPageApiEndpoint.updateLandingPage, Utils.trimUndefined(params))
    .then(() => {
      return
    });
  },
  deleteLandingPage: async({ commit }, params: DeleteLandingPageParams) => {
    return await api.delete(LandingPageApiEndpoint.deleteLandingPage, {
      data: Utils.trimUndefined(params)
    })
    .then(() => {
      return
    });
  },
  fetchLandingPageNameList: async({ commit }) => {
    return await api.get(LandingPageApiEndpoint.fetchLandingPageNameList, {
      params: Utils.trimValidData({
      })
    }).then(res => {
      return res.data.data as FetchLandingPageNameListResponse;
    });
  },
};

export const LandingPageMutations: MutationTree<LandingPageState> = {
  saveSearchConditions: (state, params: FetchLandingPageListParams)=>{
    state.searchSeasonId = params.season_id;
    state.searchCampaignId = params.campaign_id;
    state.searchLandingPageId = params.landing_page_id;
    // localStorage.removeItem('token');
    // localStorage.removeItem('refresh');
  }
}

export const LandingPageGetterTree: GetterTree<LandingPageState, RootState> = {
  // landing_pageId : state => state.searchLandingPageId,
  seasonId: state => state.searchSeasonId,
  campaignId: state => state.searchCampaignId,
  landingPageId: state => state.searchLandingPageId,
}

export const landingPageModule: Module<LandingPageState, RootState> = {
  namespaced: true,
  state: state,
  getters: LandingPageGetterTree,
  mutations: LandingPageMutations,
  actions: LandingPageActionTree
};