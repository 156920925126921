import api from '@/services/api';
import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import store from '@/store'
import { RootState } from '@/store';

import { SignInResponse, RefreshResponse } from '@/models/ApiResponse';
import { GlobalState } from '../types';


import { Utils } from '@/Utility';

const state: GlobalState = {
  ApiCallCount: 0,
  ErrorMessage: null,
}

export const GlobalActionTree: ActionTree<GlobalState, RootState> = {
  EnterApi({commit}){
    commit('enterApi')
  },
  ExitApi({commit}){
    commit('exitApi')
  },
  Refresh({commit}){
    commit('refresh')
  },
  SetError({commit}, message: string|null){
    commit('error', message)
  }
};

export const GlobalMutations: MutationTree<GlobalState> = {
  enterApi(state){
    state.ApiCallCount++;
  },
  exitApi(state){
    state.ApiCallCount--;
  },
  refresh(state){
    state.ApiCallCount = 0;
  },
  error(state, message){
    state.ErrorMessage = message
  }
}

export const GlobalGetterTree: GetterTree<GlobalState, RootState> = {
  apiCallCount: state => state.ApiCallCount,
  errorMessage: state => state.ErrorMessage,
}

export const globalModule: Module<GlobalState, RootState> = {
  namespaced: true,
  state: state,
  getters: GlobalGetterTree,
  mutations: GlobalMutations,
  actions: GlobalActionTree
};