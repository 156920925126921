
import { SignInParams } from '@/store/modules/auth';
import store from '@/store';

import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components:{}
})
export default class LoginView extends Vue{
  email  = "";
  password  = "";

  public signin() {
    
    
    const ref = (this.$refs.credential as Vue & { validate: () => boolean });
    
    if(!ref){
      return;
    }
    if(!(ref.validate())){
      return;
    }
    
    store.dispatch('auth/signin', {
      email: this.email,
      password: this.password
    } as SignInParams).then((result) =>{
      this.$router.push({ name: "lead" });
    })
  }
  public forgotPassword() {
    this.$router.push({name: "forget_password"})
  }

  created(){
    if (this.$store.getters["auth/isLoggedIn"]) {
      this.$router.push({ name: "lead" });
    }
  }

  get pwRules(){
    return [
        (v: string) => !!v || "パスワードを入力してください", 
        ]
  }

  get mailRules(){
    return [
        (v: string) => !!v || "入力必須項目です",
        (v: string) => /.+@.+\..+/.test(v) || "有効なメールアドレスを入力してください",
      ]
  }
}
