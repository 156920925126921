
import { Component, Prop, Vue } from "vue-property-decorator";
import VCombobox from "vuetify";
import { Utils } from "@/Utility";
import store from "@/store";
import { PostLandingPageParams } from "@/store/modules/landingPage";
import { CampaignName } from "@/models/Campaign";
import { LandingPage } from "@/models/LandingPage";
import VueClipboard from "vue-clipboard2";
import Toasted from "vue-toasted";

@Component({
  components: {},
})
export default class CreateLandingPageDialog extends Vue {
  @Prop({ default: false })
  show!: boolean;

  @Prop({ default: 500 })
  width!: number;
  @Prop({ default: undefined, required: true, type: Object })
  landingPage!: LandingPage;

  get pvCountSnippet() {
    return `import axios from 'axios';
axios.post("https://leads.utsunomiyabrex.net/api/v1/campaigns/landing_page/pv",{
  landing_page_id: "${this.landingPage?.landing_page_id}"
})
.then(function (response) {
  console.log(response);
})
.catch(function (error) {
  console.log(error);
});`;
  }

  //
  get playStoreCountSnippet() {
    return `import axios from 'axios';
axios.post("https://leads.utsunomiyabrex.net/api/v1/campaigns/landing_page/store_link_click",{
  landing_page_id: "${this.landingPage?.landing_page_id}",
  store_type: 0
})
.then(function (response) {
  console.log(response);
})
.catch(function (error) {
  console.log(error);
});`;
  }

  get appStoreCountSnippet() {
    return `import axios from 'axios';
axios.post("https://leads.utsunomiyabrex.net/api/v1/campaigns/landing_page/store_link_click",{
  landing_page_id: "${this.landingPage?.landing_page_id}",
  store_type: 1
})
.then(function (response) {
  console.log(response);
})
.catch(function (error) {
  console.log(error);
});`;
  }

  mounted() {
    console.log("mounted");
  }

  snippetCopySuccess() {
    this.$toasted.success("クリップボードにコピーしました");
  }

  snippetCopyError() {
    this.$toasted.error("予期せぬエラーが発生しました");
  }

  copyPvCountSnippet() {
    this.$copyText(this.pvCountSnippet, this.$refs.container)
      .then((result) => {
        result;
        this.$toasted.success("クリップボードにコピーしました");
      })
      .catch((error) => {
        this.$toasted.error("予期せぬエラーが発生しました");
      });
  }

  copyPlayStoreClickSnippet() {
    this.$copyText(this.playStoreCountSnippet, this.$refs.container)
      .then((result) => {
        result;
        this.$toasted.success("クリップボードにコピーしました");
      })
      .catch((error) => {
        this.$toasted.error("予期せぬエラーが発生しました");
      });
  }
  copyAppsStoreClickSnippet() {
    this.$copyText(this.appStoreCountSnippet, this.$refs.container)
      .then((result) => {
        result;
        this.$toasted.success("クリップボードにコピーしました");
      })
      .catch((error) => {
        this.$toasted.error("予期せぬエラーが発生しました");
      });
  }
}
