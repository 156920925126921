
import draggable from "vuedraggable";
import { GameName } from "@/models/Game";
import { Capacity } from "@/models/Capacity";
import GameCapacityRow from "./GameCapacityRow.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import VCombobox from "vuetify";
import { SeasonName } from "@/models/Season";
import store from "@/store";
import { PostCampaignParams, CampaignGame } from "@/store/modules/campaign";
import { CampaignSummaryDetails, CampaignSummaryGameList, CampaignSummaryWinningTarget, WinningTarget, CampaignType, CampaignAnnouncementType, CampaignTarget} from '@/models/Campaign'



@Component({
  components: {
    GameCapacityRow,
    draggable,
  },
})
export default class UpdateCampaignDialog extends Vue {
  @Prop({ default: false })
  show!: boolean;

  @Prop({ default: 500 })
  width!: number;

  @Prop({
    type: Array,
    default: Array(0),
    required: true,
  })
  seasons!: Array<SeasonName>;

  @Prop({
    type: Array,
    default: Array(0),
    required: true,
  })
  games!: Array<GameName>;

  @Prop({
    type: Object,
    default: undefined,
    required: true,
  })
  details!: CampaignSummaryDetails;


  public selectCampaignStartAt = false
  public selectCampaignEndAt = false
  public selectLotteryOn = false;

  public campaignStartTime = "";
  public selectCampaignStartTime = false;
  public campaignEndTime = "";
  public selectCampaignEndTime = false;

  public seasonName: SeasonName | null;

  public campaignName = "";
  public campaignCode = "";
  public campaignType: CampaignType | null;
  public campaignAnnouncementType: CampaignAnnouncementType | null;
  public campaignTarget: CampaignTarget | null;
  public eventArea: string|null = null;
  public eventName: string|null = null;
  public lotteryOn:string|null = null;
  public campaignStartAt = "";
  public campaignEndAt = "";
  public campaignImageUrl = "";
  public eTicketAcquisitionUrl:string|null = null;
  public winnersAnnouncementGuidance = "";
  public informationWinnersGuidanceForApp = "";
  public informationLoserGuidanceForApp = "";
  public informationWinnersGuidanceForMail = "";
  public informationLoserGuidanceForMail = "";
  public deleteFlag = false;


  public selectedConditionTmp?: WinningTarget;

  capacity: Array<Capacity> = new Array(0);
  winningTarget: Array<WinningTarget> = new Array(0);


  
  get campaignSeasonRules(){
    return [
      (v: SeasonName) => v ?? "入力必須項目です"
    ]
  }

  get campaignNameRules(){
    return [
      (v: string) => v?.length > 0 || "入力必須項目です"
    ]
  }

  get campaignCodeRules(){
    return [
      (v: string) => v?.length > 0 || "入力必須項目です"
    ]
  }

  get campaignTypeRules(){
    return [
      (v: CampaignType) => v ?? "入力必須項目です"
    ]
  }

  get lotteryOnRules(){
    return [
      (v: string) => (this.campaignType?.code_value != 3 ) || v?.length > 0 || "入力必須項目です"
    ]
  }
  
  get campaignStartDateRules() {
    return [(v: string) => v?.length > 0 || "入力必須項目です"];
  }
  
  get campaignEndDateRules() {
    return [(v: string) => v?.length > 0 || "入力必須項目です"];
  }
  
  
  get campaignStartTimeRules() {
    return [(v: string) => v?.length > 0 || "入力必須項目です"];
  }
  
  get campaignEndTimeRules() {
    return [(v: string) => v?.length > 0 || "入力必須項目です"];
  }
  
  get imageUrlRules() {
    return [
      (v: string) => v?.length > 0 || "入力必須項目です",
      (v: string) => /^https?:\/\/[\w!?/+\-_~;.,*&@#$%()='[\]]+$/.test(v) || "正しいURLを入力してください"
      ];
  }

  get ticketAcquisitionUrlRules(){
    return [
      (v: string) => v?.length == 0 || /^https?:\/\/[\w!?/+\-_~;.,*&@#$%()='[\]]+$/.test(v) || "正しいURLを入力してください"
    ]
  }
  
  get winnersAnnouncementGuidanceRules() {
    return [(v: string) => v?.length > 0 || "入力必須項目です"];
  }

  get informationWinnersGuidanceForAppRules() {
    return [(v: string) => v?.length > 0 || "入力必須項目です"];
  }
  get informationWinnersGuidanceForMailRules() {
    return [(v: string) => v?.length > 0 || "入力必須項目です"];
  }
  get informationLoserGuidanceForMailRules() {
    return [(v: string) => v?.length > 0 || "入力必須項目です"];
  }
  get informationLoserGuidanceForAppRules() {
    return [(v: string) => v?.length > 0 || "入力必須項目です"];
  }



  get campaignTypes(): Array<CampaignType> {
    return new Array<CampaignType>(
      {
        name: "先着型",
        code_value: 1,
      },
      {
        name: "即時抽選型",
        code_value: 2,
      },
      {
        name: "一括抽選型",
        code_value: 3,
      }  
    );
  }

  get campaignAnnouncementTypes(): Array<CampaignAnnouncementType> {
    return new Array<CampaignAnnouncementType>(
      {
        name: "TV",
        code_value: 0,
      },
      {
        name: "ラジオ",
        code_value: 1,
      },
      {
        name: "チラシ",
        code_value: 2,
      },
      {
        name: "講演",
        code_value: 3,
      },
      {
        name: "新聞",
        code_value: 4,
      },
      {
        name: "雑誌",
        code_value: 5,
      },
      {
        name: "ネット広告",
        code_value: 6,
      },
      {
        name: "ポスティング",
        code_value: 7,
      }
    );
  }

  get campaignTargets(): Array<CampaignTarget> {
    return new Array<CampaignTarget>(
      {
        name: "小学生",
        code_value: 0,
      },
      {
        name: "中学生",
        code_value: 1,
      },
      {
        name: "高校生",
        code_value: 2,
      },
      {
        name: "専門",
        code_value: 3,
      },
      {
        name: "大学生",
        code_value: 4,
      },
      {
        name: "幼稚園・保育園",
        code_value: 5,
      },
      {
        name: "シニア",
        code_value: 6,
      },
      {
        name: "社会人",
        code_value: 7,
      },
      {
        name: "外国人",
        code_value: 8,
      }
    );
  }

  constructor() {
    super();
    this.seasonName = null;
    this.campaignType = null;
    this.campaignAnnouncementType = null;
    this.campaignTarget = null;
  }
  
  get options() {
    return {
      animation: 200,
    };
  }


  get selectableConditions(): Array<WinningTarget> {
    let cd = Array<WinningTarget>(
      {id: 1, name:"新規かつ当選回数が0回"},
      {id: 2, name:"新規かつ当選回数が1回以上"},
      {id: 3, name:"既存かつ当選回数が0回"},
      {id: 4, name:"既存かつ当選回数が1回以上"}
    ).filter((c) => {
      return this.winningTarget.find((d) => d.id == c.id) == null;
    });
    return cd;
  }

  formatter(d: string){
    return new Date(d).getDate()
  }

  addTarget(item: WinningTarget) {
    if (!item) return;
    this.winningTarget.push(item);
    this.selectedConditionTmp = undefined;
    // eslint-disable-next-line
    const element = this.$refs.chooseCondition as any;

    element.clearableCallback();
  }

  addCapacityElement() {
    this.capacity.push({
      game: null,
      single: null,
      pair: null
    });
  }

  removeCapacityElement(item: Capacity){
    this.capacity.splice(this.capacity.indexOf(item), 1)
  }
  
  deleteCondition(item: WinningTarget) {
    this.winningTarget.splice(this.winningTarget.indexOf(item), 1);
  }

  commit() {
    
    

    const ref = (this.$refs.form as Vue & { validate: () => boolean });
    
    if(!ref){
      return;
    }
    if(!(ref.validate())){
      store.dispatch('global/SetError', "入力項目にエラーがあります。")
      return;
    }

    if(!(this.winningTarget?.length > 0)){
      store.dispatch('global/SetError', "抽選条件はひとつ以上選択する必要があります。")
      return;
    }

    
    const [campaignStartAtYear, campaignStartAtMonth, campaignStartAtDay] = this.campaignStartAt!.split('-')
    const [campaignEndAtYear, campaignEndAtMonth, campaignEneAtDay] = this.campaignEndAt!.split('-')

    const request = {
      campaign_id: this.details.campaign_id,
      season_id: this.seasonName!.season_id,
      campaign_name: this.campaignName,
      campaign_code: this.campaignCode,
      campaign_type: this.campaignType!.code_value,
      campaign_announcement_type: this.campaignAnnouncementType?.code_value ?? null,
      campaign_target: this.campaignTarget?.code_value ?? null,
      campaign_area: this.eventArea,
      event_name: this.eventName,
      lottery_on: this.lotteryOn,
      lottery_reminder_send_mail_address_user_id: null,
      campaign_start_at: `${campaignStartAtYear}/${campaignStartAtMonth.padStart(2, '0')}/${campaignStartAtDay.padStart(2, '0')} ${this.campaignStartTime}`,
      campaign_end_at: `${campaignEndAtYear}/${campaignEndAtMonth.padStart(2, '0')}/${campaignEneAtDay.padStart(2, '0')} ${this.campaignEndTime}`,
      campaign_image_url: this.campaignImageUrl,
      e_ticket_acquisition_url: this.eTicketAcquisitionUrl,
      winning_announcement_guidance: this.winnersAnnouncementGuidance,
      information_winners_guidance_for_app: this.informationWinnersGuidanceForApp,
      information_winners_guidance_for_mail: this.informationWinnersGuidanceForMail,
      information_loser_guidance_for_app: this.informationLoserGuidanceForApp,
      information_loser_guidance_for_mail: this.informationLoserGuidanceForMail,
      winning_target: this.campaignType?.code_value == 1 ? '0': this.winningTarget.map((wt: WinningTarget)=>wt.id).join(','),
      game_list: this.capacity.reduce((acc: Array<CampaignGame>, cp: Capacity)=>{
        acc.push({
          game_id: cp.game!.game_id,
          number_of_one_ticket: this.campaignType!.code_value == 2 ? null : cp.single,
          number_of_two_pair_tickets: this.campaignType!.code_value == 2 ? null : cp.pair,
          winning_rate_for_one_ticket: this.campaignType!.code_value == 2 ? cp.single : null,
          winning_rate_for_two_pair_tickets: this.campaignType!.code_value == 2 ? cp.pair : null
        })
        return acc
      }, new Array(0)),
      delete_flag: this.deleteFlag
    }

    

    store.dispatch('campaign/updateCampaign', request).then((result) =>{
      this.$emit('onUpdate')
    })
  }
  
  mounted() {
    
    

    // this.winningTarget.push(this.selectableConditions[0]);
    // this.selectedConditionTmp = this.selectableConditions[0];


    this.seasonName = this.seasons.find((s)=>s.season_id == this.details.season_id)!;
    this.campaignName = this.details.campaign_name;
    this.campaignCode = this.details.campaign_code;
    this.campaignType = this.campaignTypes.find((ct: CampaignType)=>ct.code_value == this.details.campaign_type)!;
    this.campaignAnnouncementType = this.campaignAnnouncementTypes.find((cat: CampaignAnnouncementType)=>cat.code_value == this.details.campaign_announcement_type) ?? null;
    this.campaignTarget = this.campaignTargets.find((ct: CampaignTarget)=>ct.code_value == this.details.campaign_target) ?? null;
    this.eventArea = this.details.campaign_area;
    this.eventName = this.details.event_name;
    this.lotteryOn = this.details.lottery_on?.split(' ')[0].replace('/','-') ?? null
    
    this.campaignStartAt = this.details.campaign_start_at.split(' ')[0].replace('/', '-');
    this.campaignEndAt = this.details.campaign_end_at.split(' ')[0].replace('/', '-');
    this.campaignStartTime = this.details.campaign_start_at.split(' ')[1];
    this.campaignEndTime = this.details.campaign_end_at.split(' ')[1];



    this.campaignImageUrl = this.details.campaign_image_url
    this.eTicketAcquisitionUrl = this.details.e_ticket_acquisition_url;
    this.informationWinnersGuidanceForApp = this.details.information_for_winners_guidance;
    this.informationWinnersGuidanceForMail = this.details.information_for_winners_guidance_for_mail;
    this.informationLoserGuidanceForApp = this.details.information_for_loser_guidance;
    this.informationLoserGuidanceForMail = this.details.information_for_loser_guidance_for_mail;
    this.winnersAnnouncementGuidance = this.details.winning_announcement_guidance;
    this.capacity = this.details.game_list.reduce((acc: Array<Capacity>, game: CampaignGame)=>{
      acc.push({
        game: this.seasonGame.find((sg)=>sg.game_id == game.game_id)!,
        single: this.details.campaign_type == 2 ? game.winning_rate_for_one_ticket : game.number_of_one_ticket,
        pair: this.details.campaign_type == 2 ? game.winning_rate_for_two_pair_tickets: game.number_of_two_pair_tickets
      });
      return acc;
    }, new Array(0));
    this.winningTarget = this.details.winning_target_list.reduce((acc: Array<WinningTarget>, wt: CampaignSummaryWinningTarget)=>{
      const w = this.selectableConditions.find((sc: WinningTarget)=>sc.id==wt.kind)
      if(w){
        acc.push(w);
      }
      return acc;
    }, new Array(0));
    this.deleteFlag = this.details.delete_flag;
    this.selectedConditionTmp = this.selectableConditions[0];
  }

  @Watch('campaignType')
  onCampaignTypeChanged(newType: CampaignType, oldType: CampaignType){
    
    if(!oldType){
      return;
    }
    if(oldType.code_value == 2 && newType.code_value != 2){
      this.capacity.splice(0);
    }else if(oldType.code_value != 2 && newType.code_value == 2){
      this.capacity.splice(0);
    }
  }


  
  get seasonGame(){
    
    return this.games.filter((g) => g.season_id == this.seasonName?.season_id);
  }
}
