
import { Alena, AlenaName } from '@/models/Alena'
import { Component, Prop, Vue } from "vue-property-decorator";
import PageContent from "@/components/PageContent.vue";
import CreateAlenaDialog from './_components/CreateAlenaDialog.vue';
import UpdateAlenaDialog from './_components/UpdateAlenaDialog.vue';
import store from '@/store'
import { FetchAlenaListResponse,FetchAlenaNameListResponse } from '@/store/modules/alena'

@Component({
  components: {
    PageContent,
    CreateAlenaDialog,
    UpdateAlenaDialog
  },
})
export default class AlenaView extends Vue {
  showCreateDialog = false;
  showUpdateDialog = false;
  selectedAlena: Alena|null = null;

  get pageTitle(): string{
    return "会場管理画面";
  }
  get tableHeader() {
    return [
      { text: "会場名", value: "name", width: "auto" },
      { text: "略称", value: "abb_name", width: "auto" },
      { text: "郵便番号", value: "zipcode", width: "auto" },
      { text: "住所", value: "address", width: "auto" },
      { text: "電話番号", value: "tel", width: "auto" },
      { text: "マップ", value: "showMap", width: "auto" },
      { text: "データ作成日時", value: "created_at", width: "auto" },
      { text: "", value: "showDetails", width: "auto" },
    ];
  }
  
  alenas: Array<Alena>
  alenaNames = Array<AlenaName>(0)

  constructor(){
    super()
    this.alenas = new Array(0);
  }
  
  public create() {
    this.showCreateDialog = true;
  }
  public onCancel() {
    this.showCreateDialog = false;
  }
  public onCreate(){
    this.showCreateDialog = false;
    this.query()
  }

  public showDetails(item: Alena) {
    
    this.selectedAlena = item;
    this.showUpdateDialog = true;
  }

  public onCancelUpdate(){
    this.selectedAlena = null;
    this.showUpdateDialog = false;
  }

  public onUpdate(){
    this.selectedAlena = null;
    this.showUpdateDialog = false;

    this.query()
  }

  public onDelete(){
    this.selectedAlena = null;
    this.showUpdateDialog = false;

    this.query()
  }
  
  public mounted(){
    this.query()
  }

  onChange(e: AlenaName){
    this.selectedAlenaName = e;
    store.dispatch('alena/fetchAlenaList',{
          alena_id: this.selectedAlenaName?.alena_id
        }).then((result: FetchAlenaListResponse)=>{
          
          this.alenas = result.alena_list;
          
        })
  }

  selectedAlenaName: AlenaName | null = null;
  query(){
    var searchAlenaId = store.getters['alena/searchAlenaId'] ?? null;

    store.dispatch('alena/fetchAlenaNameList')
      .then((result: FetchAlenaNameListResponse)=>{
        this.alenaNames = result.alena_name_list;

        this.selectedAlenaName = this.alenaNames.find((an)=>an.alena_id == searchAlenaId) ?? null;

        store.dispatch('alena/fetchAlenaList',{
          alena_id: this.selectedAlenaName?.alena_id
        }).then((result: FetchAlenaListResponse)=>{
          
          this.alenas = result.alena_list;
          
        })
      })
  }
}
