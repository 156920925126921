
import { Component, Prop, Vue } from "vue-property-decorator";
import VCombobox from "vuetify";
import { Utils } from '@/Utility'
import store from '@/store'
import { PostAlenaParams } from '@/store/modules/alena'

class ConditionType {
  id: number;
  name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}

@Component({
  components: {},
})
export default class CreateAlenaDialog extends Vue {
  @Prop({ default: false })
  show!: boolean;

  @Prop({ default: 500 })
  width!: number;

  
  public name: string;
  public abb_name: string;
  public zipcode: string;
  public pref: string;
  public city: string;
  public address_1: string;
  public tel: string;
  public lat: string;
  public lon: string;

  constructor(){
    super()
    this.name = "";
    this.abb_name = "";
    this.zipcode = "";
    this.pref = "";
    this.city = "";
    this.address_1 = "";
    this.tel = "";
    this.lat = "";
    this.lon = "";
  }

  get prefecture(){
    return Utils.prefectures();
  }

  get alenaNameRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
    ]
  }
  
  get alenaAbbNameRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
    ]
  }

  get zipCodeRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
      (v: string) => /^[0-9]{7}$/.test(v) || '7桁の数字で入力してください'
    ]
  }

  get prefectureRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
    ]
  }
  get cityRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
    ]
  }

  
  get addressRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
    ]
  }
  get phoneNumberRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
      (v: string) => /^\d{10}\d?$/.test(v) || '10桁~11桁の数字で入力してください'
    ]
  }

  get latitudeRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
      (v: string) => /^\d+(\.\d{1,9})?$/.test(v) || '小数点以下9桁までで入力してください',
      (v: string) => Utils.between(Number.parseFloat(v), -90, 90) || '-90~90の範囲で入力してください'
    ]
  }
  get longitudeRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
      (v: string) => /^\d+(\.[0-9]{1,9})?$/.test(v) || '小数点以下9桁までで入力してください',
      (v: string) => Utils.between(Number.parseFloat(v), -180, 180) || '-180~180の範囲で入力してください'
    ]
  }
  


  get options() {
    return {
      animation: 200,
    };
  }

  commit() {
    const ref = (this.$refs.form as Vue & { validate: () => boolean });
    
    if(!ref){
      return;
    }
    if(!(ref.validate())){
      return;
    }

    store.dispatch('alena/createAlena', {
      name: this.name,
      abb_name: this.abb_name,
      zipcode: this.zipcode,
      prefecture: this.pref,
      city: this.city,
      address_1: this.address_1,
      tel: this.tel,
      lat: this.lat,
      lon: this.lon
    } as PostAlenaParams).then((result)=>{
      result;
      this.$emit("onCreate")
    })
  }
  mounted() {
    console.log("mounted")
  }
}
