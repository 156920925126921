import api from '@/services/api';
import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import store from '@/store'
import { RootState } from '@/store';

import { SignInResponse, RefreshResponse } from '@/models/ApiResponse';
import { CampaignStatisticsState } from '../types';

import { CampaignStatistics } from '@/models/Campaign';
import { CampaignLotteryDetails, LotteryChangeInfo } from '@/models/CampaignLotteryDetails'

import { Utils } from '@/Utility';

const CampaignApiEndpoint = {
  fetchStatistics: 'api/v1/campaigns/statistics'
}



export type FetchCampaignStatisticsListParams = {
  // シーズンID
  season_id: string,
  campaign_id: Array<string>|null,
  campaign_start_at: string|null,
  campaign_end_at: string|null,
  game_at: string|null,
}

const state: CampaignStatisticsState = {
  searchSeasonId: '',
  searchCampaignId: null,
  searchEndDate: null,
  searchStartDate: null,
  searchGameAt: null
}

// サマライズされたキャンペーン一覧のレスポンス
export type FetchCampaignStatisticsListResponse = {
  campaign_stats_list: Array<CampaignStatistics>
}

export const CampaignActionTree: ActionTree<CampaignStatisticsState, RootState> = {
  fetchCampaignStatisticsList: async({ commit }, params: FetchCampaignStatisticsListParams) => {
    return await api.get(CampaignApiEndpoint.fetchStatistics, {
      params: Utils.trimValidData({
        season_id: params.season_id,
        'campaign_id[]': params.campaign_id,
        campaign_start_at: params.campaign_start_at,
        campaign_end_at: params.campaign_end_at,
        game_at: params.game_at,
      })
    }).then(res => {
      const fetchCampaignStatisticsListResponse = res.data.data as FetchCampaignStatisticsListResponse
      commit('saveSearchConditions', params)
      return fetchCampaignStatisticsListResponse
    });
  },
};

export const CampaignMutations: MutationTree<CampaignStatisticsState> = {
  saveSearchConditions: (state, params: FetchCampaignStatisticsListParams)=>{
    state.searchSeasonId = params.season_id;
    state.searchCampaignId = params.campaign_id;
    state.searchStartDate = params.campaign_start_at;
    state.searchEndDate = params.campaign_end_at;
    state.searchGameAt = params.game_at;
    // state.searchCampaignSummaryId = params.campaignSummary_id;
    // localStorage.removeItem('token');
    // localStorage.removeItem('refresh');
  }
}

export const CampaignGetterTree: GetterTree<CampaignStatisticsState, RootState> = {
  // campaignSummaryId : state => state.searchCampaignSummaryId,
  seasonId: state => state.searchSeasonId,
  campaignId: state => state.searchCampaignId,
  startDate: state => state.searchStartDate,
  endDate: state => state.searchEndDate,
  gameAt: state => state.searchGameAt,
}

export const campaignStatisticsModule: Module<CampaignStatisticsState, RootState> = {
  namespaced: true,
  state: state,
  getters: CampaignGetterTree,
  mutations: CampaignMutations,
  actions: CampaignActionTree
};