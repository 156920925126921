
import { Component, Prop, Vue } from "vue-property-decorator";
import VCombobox from "vuetify";
import { Utils } from '@/Utility'
import store from '@/store'
import { PutTeamParams, DeleteTeamParams } from '@/store/modules/team'
import { Team } from '@/models/Team'
import DeleteDialog from '@/components/DeleteDialog.vue'

class ConditionType {
  id: number;
  name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}

@Component({
  components: {
    DeleteDialog
  },
})
export default class UpdateTeamDialog extends Vue {
  @Prop({ default: false })
  show!: boolean;

  @Prop({ default: 500 })
  width!: number;

  @Prop({
    type: Object,
    default: undefined,
    required: true
  })
  details!: Team

  
  public name = "";
  public abb_name = "";
  public club_id = "";
  public color_primary: string|null = null;
  public color_secondary: string|null = null;
  public icon_white_url = "";
  public icon_black_url = "";
  public website: string|null = null;

  constructor(){
    super()
  }

  get prefecture(){
    return Utils.prefectures();
  }

  get teamNameRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
    ]
  }
  
  get teamAbbNameRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
    ]
  }

  get clubIdRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
      (v: string) => /^\d+$/.test(v) || '7桁の数字で入力してください'
    ]
  }

  get colorRules(){
    return [
      (v: string) => !v || (/^#[\da-fA-F]{6}/.test(v) || "#aabbccのような形式で入力してください")
    ]
  }

  get iconUrlRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
      (v: string) => /^https?:\/\/.+\..+$/.test(v) || '正しいURLを入力してください'
    ]
  }

  get websiteRules(){
    return [
      (v: string) => !v || (/^https?:\/\/.+\..+$/.test(v) || '正しいURLを入力してください')
    ]
  }



  get options() {
    return {
      animation: 200,
    };
  }


  showDeleteDialog = false;
  remove(){

    store.dispatch('team/deleteTeam', {
      team_id: this.details.team_id,
    } as DeleteTeamParams).then((result)=>{
      result;
      this.$emit("onDelete")
    })
  }
  commit() {
    const ref = (this.$refs.form as Vue & { validate: () => boolean });
    
    if(!ref){
      return;
    }
    if(!(ref.validate())){
      return;
    }

    store.dispatch('team/updateTeam', {
      team_id: this.details.team_id,
      name: this.name,
      abb_name: this.abb_name,
      club_id: Number(this.club_id),
      color_primary: this.color_primary,
      color_secondary: this.color_secondary,
      icon_white_url: this.icon_white_url,
      icon_black_url: this.icon_black_url,
      website: this.website
    } as PutTeamParams).then((result)=>{
      result;
      this.$emit("onUpdate")
    })
  }
  mounted() {
    
    
    
    this.name = this.details.team_name;
    this.abb_name = this.details.abb_name;
    this.club_id = this.details.club_id.toString();
    this.color_primary = this.details.color_primary;
    this.color_secondary = this.details.color_secondary;
    this.icon_white_url = this.details.icon_white_url;
    this.icon_black_url = this.details.icon_black_url;
    this.website = this.details.website;
  }
}
