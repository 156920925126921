
import { Component, Prop, Vue } from "vue-property-decorator";
import { saveAs } from 'file-saver'

@Component({ components: {} })
export default class CampaignQRCodeDialog extends Vue {
  @Prop({ default: false })
  show!: boolean;

  @Prop({ default: 500 })
  width!: number;
  

  
  @Prop({
    type: String,
    default: "",
    required: true})
  qrCodeData = ""
  @Prop({
    type: String,
    default: "",
    required: true})
  campaignCode = ""


  download(){
    saveAs(this.qrCodeData, `campaign_${this.campaignCode}.png`);
  }
  
}
