
import { Component, Prop, Vue } from "vue-property-decorator";
import VCombobox from "vuetify";
import { Utils } from "@/Utility";
import { SeasonName } from "@/models/Season";
import { TeamName } from "@/models/Team";
import { AlenaName } from "@/models/Alena";
import store from "@/store";
import { UpdateProfileParams } from "@/store/modules/lead";
import { Lead } from "@/models/Lead";

type EmailSendable = {
  stat: boolean,
  name: string
}

@Component({
  components: {},
})
export default class EditLeadsInfoDialog extends Vue {
  @Prop({ default: false })
  show!: boolean;

  @Prop({ default: 500 })
  width!: number;

  @Prop({
    type: Object,
    default: undefined,
    required: true,
  })
  details!: Lead;


  decrypt(word: string){
    return Utils.decrypt(word)
  }

  get emailSendSelection(){
    return [
      {
        stat: true,
        name: "送信可"
      },
      {
        stat: false,
        name: "送信不可"
      },
    ]
  }

  isBlacklist = false
  emailSendStatus: EmailSendable|null = null

  commit() {
    const ref = this.$refs.form as Vue & { validate: () => boolean };
    console.log(ref);
    if (!ref) {
      return;
    }
    if (!ref.validate()) {
      return;
    }

    store
      .dispatch("lead/updateProfile", {
        email: this.details.email,
        is_black_list: this.isBlacklist,
        is_send_mail: this.emailSendStatus!.stat
      } as UpdateProfileParams )
      .then((res) => {
        res;
        this.$emit("onUpdate");
      });
  }
  mounted() {
    console.log("mounted");
    this.emailSendStatus = this.emailSendSelection.find((ess)=>ess.stat == this.details.is_send_mail)!
    this.isBlacklist = this.details.is_blacklist
  }
}
