
import { Component, Prop, Vue } from "vue-property-decorator";
import PageContent from "@/components/PageContent.vue";
import store from "@/store";
import CreateUserDialog from './_components/CreateUserDialog.vue'
import UpdateUserDialog from './_components/UpdateUserDialog.vue'
import UpdateUserPasswordDialog from './_components/UpdateUserPasswordDialog.vue'
import { FetchUserListResponse } from '@/store/modules/users'
import { User } from '@/models/User'
import { use } from "vue/types/umd";


type UserCondition = {
  name: string,
  value: number
}
@Component({
  components: {
    PageContent,
    CreateUserDialog,
    UpdateUserDialog,
    UpdateUserPasswordDialog,
  },
})
export default class ManagerView extends Vue {
  get pageTitle(): string {
    return "担当者管理画面";
  }

  get tableHeader() {
    return [
      { text: "", value: "is_active", width: "auto" },
      { text: "氏名", value: "username", width: "auto" },
      { text: "メールアドレス", value: "email", width: "auto" },
      { text: "最終ログイン", value: "last_login", width: "auto" },
      { text: "データ作成日時", value: "date_joined", width: "auto" },
      { text: "", value: "change_password", width: "auto" },
      { text: "", value: "show_details", width: "auto" },
    ];
  }

  get userCondition(): Array<UserCondition>{
    return[
      {
        name: '有効',
        value: 1,
      },
      {
        name: '無効',
        value: 0,
      },
    ]
  }
  
  users = Array<User>(0);

  // public mounted() {

  // }

  showCreateDialog = false;
  public createUser(){
    this.showCreateDialog = true;
  }
  public onCreate(){
    this.showCreateDialog = false;
    this.query();
  }
  public onCancel(){
    this.showCreateDialog = false;
  }

  showUpdatePasswordDialog = false;
  updatePassword(item: User){
    this.selectedUser = item;
    this.showUpdatePasswordDialog = true;
  }

  onCancelPasswordUpdate(){
    this.selectedUser = null;
    this.showUpdatePasswordDialog = false;
  }

  onPasswordUpdate(){
    this.selectedUser = null;
    this.showUpdatePasswordDialog = false;
  }


  showUpdateDialog = false;
  selectedUser: User|null = null
  public showDetails(item: User){
    this.selectedUser = item
    this.showUpdateDialog = true;
  }
  public onUpdate(){
    this.selectedUser = null;
    this.showUpdateDialog = false;
    this.query();
  }
  public onCancelUpdate(){
    this.selectedUser = null;
    this.showUpdateDialog = false;
    this.query();
  }

  public mounted(){
    this.searchUserNameLike = store.getters['user/username'];
    this.searchUserCondition = this.userCondition.find((uc: UserCondition) => uc.value == store.getters['user/isActive']) ?? null;

    this.query();
  }

  searchUserCondition: UserCondition|null = null;
  onSelectCondition(e: UserCondition){
    this.searchUserCondition = e;

    this.query()
  }

  onBlurUserNameLike(e: string){
    console.log(e)
    e
    // this.searchUserNameLike = e;
   
    this.query()
  }

  searchUserNameLike: string | null = null;

  query(){
    store.dispatch('user/fetchUserList', {
      is_active: this.searchUserCondition?.value ?? null,
      username: this.searchUserNameLike ?? null,
    }).then((user: FetchUserListResponse)=>{
      
      this.users = user.user_list
    })

  }
}
