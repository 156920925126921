import api from '@/services/api';
import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import store from '@/store'
import { RootState } from '@/store';

import { SignInResponse, RefreshResponse } from '@/models/ApiResponse';
import { SeasonState } from '../types';

import { Season, SeasonName } from '@/models/Season';

import { Utils } from '@/Utility';

const SeasonApiEndpoint = {
  fetchTableList : 'api/v1/seasons/list',
  fetchSeasonNameList: 'api/v1/seasons/name_list',
  createSeason: "api/v1/seasons",
  updateSeason: "api/v1/seasons",
  deleteSeason: "api/v1/seasons",
}

// // チーム一覧を取得する際のパラメータ
// export type FetchSeasonListParams = {
// }

export type CreateSeasonParams = {
  name: string,
  year: number,
  current: boolean
}


export type UpdateSeasonParams = {
  season_id: string,
  current: boolean
}

export type DeleteSeasonParams = {
  season_id: string,
}

export type FetchSeasonListResponse = {
  season_list: Array<Season>
}

export type FetchSeasonNameListResponse = {
  season_name_list: Array<SeasonName>
}

const state: SeasonState = {
  searchSeasonId: null
}

export const SeasonActionTree: ActionTree<SeasonState, RootState> = {
  fetchSeasonList: async({ commit }) => {
    return await api.get(SeasonApiEndpoint.fetchTableList, {
      params: Utils.trimValidData({
      })
    }).then(res => {
      const fetchSeasonListResponse = res.data.data as FetchSeasonListResponse

      return fetchSeasonListResponse
    });
  },
  fetchSeasonNameList: async({ commit }) => {
    return await api.get(SeasonApiEndpoint.fetchSeasonNameList, {
      params: Utils.trimValidData({
      })
    }).then(res => {
      const fetchSeasonNameListResponse = res.data.data as FetchSeasonNameListResponse

      return fetchSeasonNameListResponse
    });
  },
  createSeason: async({ commit }, params: CreateSeasonParams) => {
    return await api.post(SeasonApiEndpoint.createSeason, Utils.trimValidData(params))
      .then(res => {
        res;
        return true;
      });
  },
  updateSeason: async({ commit }, params: UpdateSeasonParams) => {
    return await api.put(SeasonApiEndpoint.updateSeason, Utils.trimValidData(params))
      .then(res => {
        res;
        return true;
      });
  },
  deleteSeason: async({ commit }, params: DeleteSeasonParams) => {
    return await api.delete(SeasonApiEndpoint.deleteSeason, {data: Utils.trimUndefined(params)})
    .then(res => {
      res;
      return
    });
  },
};

export const SeasonMutations: MutationTree<SeasonState> = {
}

export const SeasonGetterTree: GetterTree<SeasonState, RootState> = {
}

export const seasonModule: Module<SeasonState, RootState> = {
  namespaced: true,
  state: state,
  getters: SeasonGetterTree,
  mutations: SeasonMutations,
  actions: SeasonActionTree
};