
import { Component, Prop, Vue } from "vue-property-decorator";
import VCombobox from "vuetify";
import { Utils } from '@/Utility'
import store from '@/store'
import { PutAlenaParams, DeleteAlenaParams } from '@/store/modules/alena'
import { Alena } from '@/models/Alena'
import DeleteDialog from '@/components/DeleteDialog.vue'


class ConditionType {
  id: number;
  name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}

@Component({
  components: {
    DeleteDialog
  },
})
export default class UpdateAlenaDialog extends Vue {
  @Prop({ default: false })
  show!: boolean;

  @Prop({ default: 500 })
  width!: number;

  @Prop({
    type: Object,
    default: undefined,
    required: true
  })
  details!: Alena

  
  public name = "";
  public abb_name = "";
  public zipcode = "";
  public pref = "";
  public city = "";
  public address_1 = "";
  public tel = "";
  public lat = "";
  public lon = "";

  constructor(){
    super()
  }

  get prefecture(){
    return Utils.prefectures();
  }

  get alenaNameRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
    ]
  }
  
  get alenaAbbNameRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
    ]
  }

  get zipCodeRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
      (v: string) => /^[0-9]{7}$/.test(v) || '7桁の数字で入力してください'
    ]
  }

  get prefectureRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
    ]
  }
  get cityRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
    ]
  }

  
  get addressRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
    ]
  }
  get phoneNumberRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
      (v: string) => /^\d{10}\d?$/.test(v) || '10桁~11桁の数字で入力してください'
    ]
  }

  get latitudeRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
      (v: string) => /^\d+(\.\d{1,9})?$/.test(v) || '小数点以下9桁までで入力してください',
      (v: string) => Utils.between(Number.parseFloat(v), -90, 90) || '-90~90の範囲で入力してください'
    ]
  }
  get longitudeRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
      (v: string) => /^\d+(\.[0-9]{1,9})?$/.test(v) || '小数点以下9桁までで入力してください',
      (v: string) => Utils.between(Number.parseFloat(v), -180, 180) || '-180~180の範囲で入力してください'
    ]
  }
  


  get options() {
    return {
      animation: 200,
    };
  }

  showDeleteDialog = false;
  remove(){

    store.dispatch('alena/deleteAlena', {
      alena_id: this.details.alena_id,
    } as DeleteAlenaParams).then((result)=>{
      result;
      this.$emit("onDelete")
    })
  }

  commit() {
    const ref = (this.$refs.form as Vue & { validate: () => boolean });
    
    if(!ref){
      return;
    }
    if(!(ref.validate())){
      return;
    }

    store.dispatch('alena/updateAlena', {
      alena_id: this.details.alena_id,
      name: this.name,
      abb_name: this.abb_name,
      zipcode: this.zipcode,
      prefecture: this.pref,
      city: this.city,
      address_1: this.address_1,
      tel: this.tel,
      lat: this.lat,
      lon: this.lon
    } as PutAlenaParams).then((result)=>{
      result;
      this.$emit("onUpdate")
    })
  }
  mounted() {
    

    this.name = this.details.name;
    this.abb_name = this.details.abb_name;
    this.zipcode = this.details.zipcode;
    this.pref = this.details.prefecture;
    this.city = this.details.city;
    this.address_1 = this.details.address_1;
    this.tel = this.details.tel;
    this.lat = this.details.lat.toString();
    this.lon = this.details.lon.toString();
  }
}
