
import { Season, SeasonName } from "@/models/Season";
import { Capacity } from "@/models/Capacity";
import { ScheduleType, Notification } from "@/models/Notification";
import { CampaignStatistics } from "@/models/Campaign";
import { Game } from "@/models/Game";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import PageContent from "@/components/PageContent.vue";
import store from "@/store";
import {
  FetchGameListParams,
  FetchGameListResponse,
} from "@/store/modules/game";
import {
  FetchCampaignStatisticsListParams,
  FetchCampaignStatisticsListResponse,
} from "@/store/modules/campaignStatistics";
import {
  FetchWinnerListParams,
  FetchWinnerListResponse,
} from "@/store/modules/campaign";
import {
  FetchCampaignSummaryListResponse,
  FetchCampaignSummaryDetailsParams,
  FetchCampaignSummaryDetailsResponse,
  FetchLotteryDetailsParams,
  FetchCampaignLotteryDetailsResponse,
  FetchChangeLotteryInfoParams,
  FetchCampaignNameListResponse,
} from "@/store/modules/campaign";
import {
  FetchSeasonListResponse,
  FetchSeasonNameListResponse,
} from "@/store/modules/season";
import { FetchTeamNameListResponse } from "@/store/modules/team";
import { FetchAlenaNameListResponse } from "@/store/modules/alena";
import { stat } from "fs";
import { Utils } from "@/Utility";
import {
  CampaignSummary,
  CampaignSummaryDetails,
  CampaignName,
} from "@/models/Campaign";

import { saveAs } from "file-saver";

@Component({
  components: {
    PageContent,
  },
})
export default class CampaignStatisticsView extends Vue {
  get pageTitle(): string {
    return "キャンペーン統計";
  }

  get tableHeader() {
    return [
      { text: "キャンペーンシーズン", value: "season_name", width: "auto" },
      { text: "キャンペーン名", value: "campaign_name", width: "auto" },
      {
        text: "キャンペーンタイプ",
        value: "campaign_type_for_display",
        width: "auto",
      },
      {
        text: "キャンペーンの告知か発信",
        value: "campaign_announcement_type_for_display",
        width: "auto",
      },
      {
        text: "キャンペーン対象",
        value: "campaign_target_for_display",
        width: "auto",
      },
      { text: "イベント名称", value: "event_name", width: "auto" },
      { text: "エリア", value: "campaign_area", width: "auto" },
      {
        text: "キャンペーンの状態",
        value: "campaign_status_for_display",
        width: "auto",
      },
      {
        text: "キャンペーン開始日時",
        value: "campaign_start_at",
        width: "auto",
      },
      { text: "キャンペーン終了日時", value: "campaign_end_at", width: "auto" },
      {
        text: "1枚チケットの申込数と当選数\r\n申込数\r\n(当選数)",
        value: "single",
        width: "auto",
      },
      {
        text: "2枚ペアチケットの申込数と当選数\r\n申込数\r\n(当選数)",
        value: "pair",
        width: "auto",
      },
      { text: "LP URL", value: "landing_page_url", width: "auto" },
      { text: "閲覧数", value: "number_of_views", width: "auto" },
      {
        text: "App Storeリンク押下数",
        value: "apps_store_presses",
        width: "auto",
      },
      {
        text: "Play Storeリンク押下数",
        value: "google_play_presses",
        width: "auto",
      },
      { text: "", value: "download_winners_list", width: "auto" },
    ];
  }

  get campaigns(): Array<any> {
    return [];
  }
  public showDetails(item: Notification) {
    console.log(item);
  }

  formatter = (d: string) => new Date(d).getDate();
  exportCsv(item: CampaignStatistics) {
    store
      .dispatch("campaign/fetchWinnerList", {
        campaign_id: item.campaign_id,
        game_id: item.game_id,
      })
      .then((winnerListResponse: FetchWinnerListResponse) => {
        store.dispatch("global/EnterApi");
        const header = [
          "メールアドレス",
          // "申込キャンペーン",
          // "観戦試合"
        ].join(",");

        const body = winnerListResponse.winner_list.map((eml: string) => {
          return [Utils.decrypt(eml)].join(",");
        });

        const now = new Date();
        const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        const content = [header].concat(body).join("\n");
        const blob = new Blob([bom, content], { type: "text/csv" });

        saveAs(
          blob,
          winnerListResponse.csv_file_name
          // `見込み顧客一覧_${now.getFullYear()}${now.getMonth()}${now.getDay()}_${now.getHours()}${now.getMinutes()}${now.getSeconds()}.csv`
        );

        store.dispatch("global/ExitApi");
      });
  }

  exportable(item: CampaignStatistics) {
    const winners =
      item.winning_for_one_ticket + item.winning_for_two_pair_ticket;
    if (item.campaign_status == 2) {
      return winners > 0;
    } else {
      return false;
    }
  }

  public showSingle(item: any) {
    if (item.campaign_type == 0) {
      return "(" + String(item.winning_for_one_ticket) + ")";
    } else {
      return (
        item.application_for_one_ticket +
        "\r\n(" +
        item.winning_for_one_ticket +
        ")"
      );
    }
    // if(item.campaign_type != 0 ?? false){
    //   return item.single.apply + "\r\n" + "(" +item.single.elect + ")";
    // }
    // return item.single.elect;
  }
  public showPair(item: any) {
    if (item.campaign_type == 0) {
      return "(" + String(item.winning_for_two_pair_ticket) + ")";
    } else {
      return (
        item.application_for_two_pair_ticket +
        "\r\n(" +
        item.winning_for_two_pair_ticket +
        ")"
      );
    }
  }

  seasonList = Array<SeasonName>(0);
  campaignNameList = Array<CampaignName>(0);
  searchSeason: SeasonName | null = null;
  serachCampaignName: Array<CampaignName> | null = null;
  selectDateStart = false;
  searchCampaignDateStart: string | null = null;
  selectDateEnd = false;
  searchCampaignDateEnd: string | null = null;
  selectDateGame = false;
  searchGameDate: string | null = null;
  async mounted() {
    this.searchCampaignDateStart =
      store.getters["campaignStatistics/startDate"];
    this.searchCampaignDateEnd = store.getters["campaignStatistics/endDate"];
    this.searchGameDate = store.getters["campaignStatistics/gameAt"];

    await store
      .dispatch("season/fetchSeasonNameList")
      .then((season: FetchSeasonNameListResponse) => {
        this.seasonList = season.season_name_list;
      });
    this.searchSeason =
      this.seasonList.find(
        (sn: SeasonName) =>
          sn.season_id == store.getters["campaignStatistics/seasonId"]
      ) ??
      this.seasonList.find((sn: SeasonName) => sn.is_current_season) ??
      null;

    await store
      .dispatch("campaign/fetchCampaignNameList", {})
      .then((result: FetchCampaignNameListResponse) => {
        this.campaignNameList = result.campaign_name_list;
      });

    var tmp = new Array<CampaignName>(0);
    store.getters["campaignStatistics/campaignId"]?.forEach((cid: string) => {
      const found = this.campaignNameList.find(
        (cn: CampaignName) => cn.campaign_id == cid
      );
      if (found) {
        tmp.push(found);
      }
    });
    if (tmp.length > 0) {
      this.serachCampaignName = tmp;
    }

    await this.query();
  }

  campaignStats = Array<CampaignStatistics>(0);
  async query() {
    store
      .dispatch("campaignStatistics/fetchCampaignStatisticsList", {
        season_id: this.searchSeason!.season_id,
        campaign_id: (this.serachCampaignName?.length ?? 0) > 0
          ? this.serachCampaignName?.map((cn: CampaignName) => cn.campaign_id)
          : null,
        campaign_start_at: this.searchCampaignDateStart
          ? `${this.searchCampaignDateStart}`
          : null,
        campaign_end_at: this.searchCampaignDateEnd
          ? `${this.searchCampaignDateEnd}`
          : null,
        game_at: this.searchGameDate ? `${this.searchGameDate}` : null,
      })
      .then((stats: FetchCampaignStatisticsListResponse) => {
        this.campaignStats = stats.campaign_stats_list;
      });
  }
  @Watch("searchSeason")
  onCampaignTypeChanged(newSeason: Season, oldSeason: Season | null) {
    if (newSeason.season_id != oldSeason?.season_id) {
      this.serachCampaignName = null;
    }
  }
  get seasonCampaign() {
    return this.campaignNameList.filter(
      (cn: CampaignName) => cn.season_id == this.searchSeason?.season_id
    );
  }
}
