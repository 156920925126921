
import draggable from "vuedraggable";
import { GameName } from "@/models/Game";
import { Capacity } from "@/models/Capacity";
import GameCapacityRow from "./GameCapacityRow.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import VCombobox from "vuetify";
import { SeasonName } from "@/models/Season";
import store from "@/store";
import { ChangeLotteryInfoParams, ChangeLotteryInfoGame } from "@/store/modules/campaign";
import {
  WinningTarget, 
  CampaignType, 
  CampaignAnnouncementType, 
  CampaignTarget
} from '@/models/Campaign'
import {
  LotteryChangeInfo,
  LotteryChangeInfoGames,
  LotteryChangeInfoWinningTarget
} from '@/models/CampaignLotteryDetails'


@Component({
  components: {
    GameCapacityRow,
    draggable,
  },
})
export default class ChangeLotteryDialog extends Vue {
  @Prop({ default: false })
  show!: boolean;

  @Prop({ default: 500 })
  width!: number;

  @Prop({
    default: undefined,
    type: Object,
    required: true
    })
  lotteryChangeInfo!: LotteryChangeInfo

  public selectedConditionTmp?: WinningTarget;

  capacity: Array<Capacity> = new Array(0);
  winningTarget: Array<WinningTarget> = new Array(0);


  get campaignType(): CampaignType{
    
      return {
        name: "一括抽選型",
        code_value: 3,
      }  
  }
  
  get options() {
    return {
      animation: 200,
    };
  }


  get selectableConditions(): Array<WinningTarget> {
    let cd = Array<WinningTarget>(
      {id: 1, name:"新規かつ当選回数が0回"},
      {id: 2, name:"新規かつ当選回数が1回以上"},
      {id: 3, name:"既存かつ当選回数が0回"},
      {id: 4, name:"既存かつ当選回数が1回以上"}
    ).filter((c) => {
      return this.winningTarget.find((d) => d.id == c.id) == null;
    });
    return cd;
  }

  addTarget(item: WinningTarget) {
    if (!item) return;
    this.winningTarget.push(item);
    this.selectedConditionTmp = undefined;
    // eslint-disable-next-line
    const element = this.$refs.chooseCondition as any;

    element.clearableCallback();
  }

  addCapacityElement() {
    this.capacity.push({
      game: null,
      single: null,
      pair: null
    });
  }
  removeCapacityElement(item: Capacity){
    this.capacity.splice(this.capacity.indexOf(item), 1)
  }
  
  
  deleteCondition(item: WinningTarget) {
    this.winningTarget.splice(this.winningTarget.indexOf(item), 1);
  }

  commit() {
    
    

    const ref = (this.$refs.form as Vue & { validate: () => boolean });
    
    if(!ref){
      return;
    }
    if(!(ref.validate())){
      store.dispatch('global/SetError', "入力項目にエラーがあります。")
      return;
    }

    if(!(this.winningTarget?.length > 0)){
      store.dispatch('global/SetError', "抽選条件はひとつ以上選択する必要があります。")
      return;
    }


    const request = {
      campaign_id: this.lotteryChangeInfo.campaign_id,
      winning_target: this.winningTarget.map((wt: WinningTarget)=>wt.id).join(','),
      game_list: this.capacity.reduce((acc: Array<ChangeLotteryInfoGame>, cp: Capacity)=>{
        acc.push({
          game_id: cp.game!.game_id,
          number_of_tickets_per_ticket: cp.single!,
          number_of_pair_tickets: cp.pair!
        })
        return acc
      }, new Array(0))
    }

    

    store.dispatch('campaign/changeLotteryInfo', request).then((result) =>{
      this.$emit('onChangeLotteryInfo')
    })
  }
  
  mounted() {
    this.capacity = this.lotteryChangeInfo.game_list.reduce((acc: Array<Capacity>, game: LotteryChangeInfoGames)=>{
      acc.push({
        game: {
          game_id: game.game_id,
          game_name: game.game_name_for_display,
          season_id: this.seasonName!.season_id
        },
        single: game.number_of_one_ticket,
        pair: game.number_of_two_pair_tickets
      });
      return acc;
    }, new Array(0));
    this.winningTarget = this.lotteryChangeInfo.winning_target_list.reduce((acc: Array<WinningTarget>, wt: LotteryChangeInfoWinningTarget)=>{
      const w = this.selectableConditions.find((sc: WinningTarget)=>sc.id==wt.kind)
      if(w){
        acc.push(w);
      }
      return acc;
    }, new Array(0));
  }
  @Prop({
    type: Array,
    default: Array(0),
    required: true,
  })
  games!: Array<GameName>;
  
  @Prop({
    type: Object,
    default: undefined,
    required: true,
  })
  seasonName!: SeasonName;
  

  get seasonGame(){
    return this.games.filter((g) => g.season_id == this.seasonName?.season_id);
  }
}
