import api from '@/services/api';
import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import store from '@/store'
import { RootState } from '@/store';

import { UserState } from '../types';
import { Utils } from '@/Utility';
import { User } from '@/models/User';

const UserApiEndpoint = {
  createUser: 'api/v1/system/users',
  fetchUserTable: 'api/v1/system/users/list',
  forgetPassword: 'api/v1/system/users/forget_password',
  confirmForgetPassword: 'api/v1/system/users/confirm_forget_password',
  changePassword: 'api/v1/system/users/change_password'
}

export type CreateUserParams = {
  email: string,
  password: string,
  last_name: string,
  first_name: string,
  is_active: boolean
}


export type FetchUserListParams = {
  username: string|null,
  is_active: number|null
}

export type FetchUserListResponse = {
  user_list: Array<User>
}

export type ForgetPasswordParams = {
  email: string
}

export type ConfitmForgetPasswordParams = {
  token: string,
  new_password: string,
}
export type ChangePasswordParams = {
  user_id: string,
  new_password: string,
}

const state: UserState = {
  searchUserName: null,
  searchIsActive: null
}

export const UserActionTree: ActionTree<UserState, RootState> = {
  createUser: async({ commit }, params: CreateUserParams) => {
    return await api.post(UserApiEndpoint.createUser, Utils.trimUndefined(params))
      .then(res => {
        res
      });
  },
  fetchUserList: async({ commit }, params: CreateUserParams) => {
    return await api.get(UserApiEndpoint.fetchUserTable, {
      params: Utils.trimValidData(params)
    }).then(res => {
      
        commit('saveSearchConditions', params)  
        return res.data.data as FetchUserListResponse
      });
  },
  forgotPassword({ commit }, params: ForgetPasswordParams) {
    return api.put(
      UserApiEndpoint.forgetPassword, 
      Utils.trimValidData(params)).then((res) => {
      commit;
      res;
    })
  },
  confirmForgotPassword({ commit },params: ConfitmForgetPasswordParams) {
    return api.put(
      UserApiEndpoint.confirmForgetPassword, 
      Utils.trimValidData(params)).then((res) => {
      commit;
      return res.data.data;
    })
  },
  changePassword({ commit },params: ChangePasswordParams) {
    return api.put(
      UserApiEndpoint.changePassword, 
      Utils.trimValidData(params)).then((res) => {
      commit;
      return res.data.data;
    })
  },
};

export const UserMutations: MutationTree<UserState> = {
  saveSearchConditions: (state, params: FetchUserListParams)=>{
    state.searchUserName = params.username,
    state.searchIsActive = params.is_active
  }

}

export const UserGetterTree: GetterTree<UserState, RootState> = {
  username: state => state.searchUserName,
  isActive: state => state.searchIsActive,
}

export const userModule: Module<UserState, RootState> = {
  namespaced: true,
  state: state,
  getters: UserGetterTree,
  mutations: UserMutations,
  actions: UserActionTree
};