
import { Component, Prop, Vue } from "vue-property-decorator";
import { DrawerMenuElement } from "@/models/DrawerMenuElement";
import store from "@/store";
import router from "@/router";

// import HelloWorld from './components/HelloWorld';
// import PageContent from "@/components/PageContent.vue";
// import CreateCampaignDialog from "@/pages/campaign/_components/CreateCampaignDialog.vue";

@Component({
  components: {},
})
export default class App extends Vue {
  get drawer() {
    let d = Array<DrawerMenuElement>();

    let customer = new DrawerMenuElement({
      name: "見込み顧客管理",
      icon_name: "mdi-wrench",
      to: "/lead",
    });

    let segmentManagement = new DrawerMenuElement({
      name: "セグメント管理",
      //  to: "/notification/segment",
      //  exact: true,
    });
    let pushNotification = new DrawerMenuElement({
      name: "Push配信管理",
      // to: "/notification/push",
      // exact: true,
    });
    let mailNotification = new DrawerMenuElement({
      name: "メール配信管理",
      // to: "/notification/mail",
      // exact: true,
    });
    let notoficationManagement = new DrawerMenuElement({
      name: "配信管理",
      icon_name: "mdi-none",
      children: new Array<DrawerMenuElement>(
        segmentManagement,
        pushNotification,
        mailNotification
      ),
    });

    let campaignStatistics = new DrawerMenuElement({
      name: "キャンペーン統計",
      to: "/statistics/campaign",
      exact: true,
    });
    let notificationStatistics = new DrawerMenuElement({
      name: "Push、メール統計",
      // to: '/statistics/notification',
      // exact: true
    });
    let statistics = new DrawerMenuElement({
      name: "統計管理",
      icon_name: "mdi-none",
      children: new Array<DrawerMenuElement>(
        campaignStatistics,
        notificationStatistics
      ),
    });

    let season = new DrawerMenuElement({
      name: "シーズン",
      icon_name: "mdi-arrow-down",
      children: new Array<DrawerMenuElement>(
        new DrawerMenuElement({
          name: "シーズン管理",
          to: "/season/season",
          exact: true,
        })
      ),
    });

    let game = new DrawerMenuElement({
      name: "試合",
      icon_name: "mdi-none",
      children: new Array<DrawerMenuElement>(
        new DrawerMenuElement({
          name: "チーム管理",
          to: "/game/team",
          exact: true,
        }),
        new DrawerMenuElement({
          name: "会場管理",
          to: "/game/alena",
          exact: true,
        }),
        new DrawerMenuElement({
          name: "試合情報管理",
          to: "/game/game",
          exact: true,
        })
      ),
    });

    let initiatives = new DrawerMenuElement({
      name: "施策",
      icon_name: "mdi-none",
      children: new Array<DrawerMenuElement>(
        new DrawerMenuElement({
          name: "キャンペーン管理",
          to: "/initiatives/campaign",
          exact: true,
        }),
        new DrawerMenuElement({
          name: "ランディングページ管理",
          to: "/initiatives/landing",
          exact: true,
        })
      ),
    });

    let user = new DrawerMenuElement({
      name: "ユーザ",
      icon_name: "mdi-none",
      children: new Array<DrawerMenuElement>(
        new DrawerMenuElement({
          name: "担当者管理",
          to: '/user',
          exact: true
        })
      ),
    });

    let bulk = new DrawerMenuElement({
      name: "データ一括投入",
      icon_name: "mdi-none",
      children: new Array<DrawerMenuElement>(
        new DrawerMenuElement({
          name: "データ一括投入",
          to: "/bulk",
          exact: true
        })
      ),
    });
    let master = new DrawerMenuElement({
      name: "マスタ管理",
      icon_name: "mdi-database",

    });

    d.push(customer);
    d.push(notoficationManagement);
    d.push(statistics);
    d.push(master);
    d.push(season);
    d.push(game);
    d.push(initiatives);
    d.push(user);
    d.push(bulk);

    return d;
  }

  get isLoggedIn(): boolean {
    return store.getters["auth/isLoggedIn"];
  }

  get menus() {
    return [
      {
        title: "ログアウト",
        onClick() {
          store.dispatch("auth/signout");
          router.push({
            name: "login",
          });
        },
      },
    ];
  }

  get callCount() {
    return store.getters["global/apiCallCount"];
  }

  get error() {
    return store.getters["global/errorMessage"];
  }

  created() {
    store.dispatch("global/SetError", null);
    store.dispatch("global/Refresh");
  }
}
