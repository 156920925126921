
import { ScheduleType, Notification } from "@/models/Notification";
import { Component, Prop, Vue } from "vue-property-decorator";
import PageContent from "@/components/PageContent.vue";

@Component({
  components: {
    PageContent,
  },
})
export default class PushNotificationView extends Vue {
  get pageTitle(): string{
    return "配信管理画面";
  }

  get tableHeader() {
    return [
      { text: "説明", value: "name", width: "auto" },
      { text: "最終配信日時", value: "last_notify", width: "auto" },
      { text: "通知スケジュール", value: "schedule_type", width: "auto" },
      { text: "データ作成日時", value: "created_at", width: "auto" },
      { text: "", value: "show_details", width: "auto" },
      { text: "", value: "resend", width: "auto" },
    ];
  }

  get notifications(): Array<Notification> {
    let gs = [];

    for (let i = 1; i < 10; i++) {
      gs.push(
        new Notification({
          name: i.toString(),
          last_notify: "2022-01-0" + i.toString() + " 12:34:56",
          schedule_type: (i % 3) == 0 ? ScheduleType.SOON : ScheduleType.WEEKLY,
          created_at: "2022-01-01 12:34:5" + i.toString(),
          resend_available_flag : (i % 4) == 2,
        })
      );
    }

    return gs;
  }
  public showDetails(item: Notification) {
    console.log(item)
  }
}
