
import { Game } from "@/models/Game";
import { Component, Prop, Vue } from "vue-property-decorator";
import PageContent from "@/components/PageContent.vue";
import store from "@/store";
import {
  FetchGameListParams,
  FetchGameListResponse,
} from "@/store/modules/game";
import { FetchSeasonNameListResponse } from "@/store/modules/season";
import { FetchTeamNameListResponse } from "@/store/modules/team";
import { FetchAlenaNameListResponse } from "@/store/modules/alena";
import CreateGameDialog from "./_components/CreateGameDialog.vue";
import UpdateGameDialog from './_components/UpdateGameDialog.vue'
import { SeasonName } from "@/models/Season";
import { AlenaName } from "@/models/Alena";
import { TeamName } from "@/models/Team";

@Component({
  components: {
    PageContent,
    CreateGameDialog,
    UpdateGameDialog
  },
})
export default class GameView extends Vue {
  showCreateDialog = false;
  showUpdateDialog = false;
  selectedGame: Game|null = null;

  selectDateStart = false;
  searchGameDateStart: string|null = null;
  selectDateEnd = false;
  searchGameDateEnd: string|null = null;

  get pageTitle(): string {
    return "試合管理画面";
  }
  get tableHeader() {
    return [
      { text: "シーズン名", value: "season_name", width: "auto" },
      { text: "試合名", value: "game_name", width: "auto" },
      { text: "試合日時", value: "game_at", width: "auto" },
      { text: "会場", value: "alena_name", width: "auto" },
      { text: "データ作成日時", value: "created_at", width: "auto" },
      { text: "", value: "showDetails", width: "auto" },
    ];
  }

  games: Array<Game>;

  seasonNameList = new Array<SeasonName>();
  teamNameList = new Array<TeamName>();
  alenaNameList = new Array<AlenaName>();

  selectedSeason: SeasonName|null = null;
  selectedTeam: TeamName|null = null;


  constructor() {
    super();
    this.games = new Array(0);
  }

  formatter = (d: string) => new Date(d).getDate();
  public create() {
    
    this.showCreateDialog = true;
  }

  public onCancel() {
    this.showCreateDialog = false;
  }

  public onCreate() {
    this.showCreateDialog = false;
    store
      .dispatch("game/fetchGameList", {
        away_team_id: null,
      } as FetchGameListParams)
      .then((result: FetchGameListResponse) => {
        
        this.games = result.game_list;
        
      });
  }

  public showDetails(item: Game) {
    
    this.selectedGame = item;
    this.showUpdateDialog = true;
  }
  public onCancelUpdate() {
    this.showUpdateDialog = false;
  }

  public onUpdate(){
    this.selectedGame = null;
    this.showUpdateDialog = false;
    store
      .dispatch("game/fetchGameList", {
        away_team_id: null,
      } as FetchGameListParams)
      .then((result: FetchGameListResponse) => {
        
        this.games = result.game_list;
        
      });
  }


  public onDelete(){
    this.selectedGame = null;
    this.showUpdateDialog = false;
    store
      .dispatch("game/fetchGameList", {
        away_team_id: null,
      } as FetchGameListParams)
      .then((result: FetchGameListResponse) => {
        
        this.games = result.game_list;
        
      });
  }

  async query(){
    await store
      .dispatch("game/fetchGameList", {
        away_team_id: this.selectedTeam?.team_id,
        season_id: this.selectedSeason?.season_id,
        start_date: this.searchGameDateStart ? `${this.searchGameDateStart.split('-').join('/')} 00:00:00`: null,
        end_date: this.searchGameDateEnd ? `${this.searchGameDateEnd.split('-').join('/')} 23:59:59`: null,
      } as FetchGameListParams)
      .then((result: FetchGameListResponse) => {
        
        this.games = result.game_list;
        
      });

  }

  public async mounted() {
    await store
      .dispatch("season/fetchSeasonNameList")
      .then((result: FetchSeasonNameListResponse) => {
        this.seasonNameList = result.season_name_list;
      });
    await store
      .dispatch("alena/fetchAlenaNameList")
      .then((result: FetchAlenaNameListResponse) => {
        this.alenaNameList = result.alena_name_list;
      });
    await store
      .dispatch("team/fetchTeamNameList")
      .then((result: FetchTeamNameListResponse) => {
        this.teamNameList = result.team_name_list;
      });

    this.selectedSeason = this.seasonNameList.find((sn)=>sn.season_id == store.getters['game/seasonId']) ?? null;
    this.selectedTeam = this.teamNameList.find((tn)=>tn.team_id == store.getters['game/teamId']) ?? null;
    this.searchGameDateStart = store.getters['game/startDate']? store.getters['game/startDate'].replace(/\//g, '-').split(' ')[0]: null;
    this.searchGameDateEnd = store.getters['game/endDate']? store.getters['game/endDate'].replace(/\//g, '-').split(' ')[0] : null;


    await this.query()
  }
}
