
import { Component, Prop, Vue } from "vue-property-decorator";
import PageContent from "@/components/PageContent.vue";
import store from "@/store";
import { Utils } from "@/Utility";
import { read } from "fs";

@Component({
  components: {
    PageContent,
  },
})
export default class BulkInsertView extends Vue {
  pageTitle = "データ一括登録";
  actions = [
    {
      title: "- 既存顧客情報の登録",
      description: "システムに既存顧客の情報を登録します。",
      file: null,
      action: function () {
        //eslint-disabled
        new Promise((resolve: (value: string)=>void, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve(reader.result as string);
          };
          reader.onerror = reject;
          reader.readAsText(this.file!);
        })
          .then((value: string) => {
            let payload: Array<string> = new Array(0);
            try {
              const dat = value.split("\n");
              if (dat.length < 2) {
                throw "csvにデータが見つかりませんでした。";
              }
              payload = dat
                .slice(1)
                .reduce((acc: Array<string>, row: string) => {
                  const columns = row.trim().split(",");
                  if (columns.length != 3) {
                    throw "データのカラム数が3でない行があります。";
                  }
                  acc.push(
                    [Utils.encrypt(columns[0]), columns[1], columns[2]].join(
                      ","
                    )
                  );
                  return acc;
                }, []);
            } catch (error: any) {
              store.dispatch("global/SetError", error.toString());
            }
            if (payload.length > 0) {
              
              store
                .dispatch("lead/customerBulkInsert", {
                  csv_data: payload,
                })
                .then((data) => {
                  data;
                  store.dispatch(
                    "global/SetError",
                    "既存顧客情報の一括登録に成功しました"
                  );
                });
            }
          })
          .catch((err: any) => {
            store.dispatch(
              "global/SetError",
              "データの読み込みに失敗しました。"
            );
          });
      },
    },
  ];
}
