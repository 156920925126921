import api from '@/services/api';
import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import store from '@/store'
import { RootState } from '@/store';

import { SignInResponse, RefreshResponse } from '@/models/ApiResponse';
import { TeamState } from '../types';

import { Team, TeamName } from '@/models/Team';

import { Utils } from '@/Utility';

const TeamApiEndpoint = {
  fetchTableList : 'api/v1/teams/list',
  fetchNameList : 'api/v1/teams/name_list',
  createTeam : 'api/v1/teams',
  updateTeam : 'api/v1/teams',
  deleteTeam : 'api/v1/teams',
}

// チーム一覧を取得する際のパラメータ
export type FetchTeamListParams = {
  // シーズンID
  team_id: string | null
}

export type DeleteTeamParams = {
  // シーズンID
  team_id: string
}

// チーム情報作成時のパラメータ
export type PostTeamParams = {
  name: string,
  abb_name: string,
  club_id: number,
  color_primary: string|null,
  color_secondary: string|null,
  icon_white_url: string,
  icon_black_url: string,
  website: string|null
}

// チーム情報更新時のパラメータ
export type PutTeamParams = {
  team_id: string,
  name: string,
  abb_name: string,
  club_id: number,
  color_primary: string|null,
  color_secondary: string|null,
  icon_white_url: string,
  icon_black_url: string,
  website: string|null
}

export type FetchTeamNameListResponse = {
  team_name_list: Array<TeamName>
}


// チーム一覧の返却
export type FetchTeamListResponse = {
  team_list: Array<Team>
}

const state: TeamState = {
  searchTeamId: null
}

export const TeamActionTree: ActionTree<TeamState, RootState> = {
  fetchTeamList: async({ commit }, params: FetchTeamListParams) => {
    return await api.get(TeamApiEndpoint.fetchTableList, {
      params: Utils.trimValidData({
        team_id: params.team_id
      })
    }).then(res => {
      const fetchTeamListResponse = res.data.data as FetchTeamListResponse
      commit('saveSearchConditions', params)

      return fetchTeamListResponse
    });
  },
  createTeam: async({ commit }, params: PostTeamParams) => {
    return await api.post(TeamApiEndpoint.createTeam, Utils.trimUndefined(params))
    .then(res => {
      res;
      return
    });
  },
  updateTeam: async({ commit }, params: PutTeamParams) => {
    return await api.put(TeamApiEndpoint.updateTeam, Utils.trimUndefined(params))
    .then(res => {
      res;
      return
    });
  },
  deleteTeam: async({ commit }, params: DeleteTeamParams) => {
    return await api.delete(TeamApiEndpoint.deleteTeam, {data: Utils.trimUndefined(params)})
    .then(res => {
      res;
      return
    });
  },
  fetchTeamNameList: async({ commit }) => {
    console.log(Utils.trimValidData({
    }))
    return await api.get(TeamApiEndpoint.fetchNameList, {
      params: Utils.trimValidData({
      })
    }).then(res => {
      return res.data.data as FetchTeamNameListResponse
    });
  },
};

export const TeamMutations: MutationTree<TeamState> = {
  saveSearchConditions: (state, params: FetchTeamListParams)=>{
    state.searchTeamId = params.team_id;
    // localStorage.removeItem('token');
    // localStorage.removeItem('refresh');
  }
}

export const TeamGetterTree: GetterTree<TeamState, RootState> = {
  teamId : state => state.searchTeamId,
}

export const teamModule: Module<TeamState, RootState> = {
  namespaced: true,
  state: state,
  getters: TeamGetterTree,
  mutations: TeamMutations,
  actions: TeamActionTree
};