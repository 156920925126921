import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme:{
    themes:{
      light: {
        primary: '#12315A',
        secondary: '#FFD400',
      }
    }
  }
});
