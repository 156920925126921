
import { LandingPage, LandingPageName } from "@/models/LandingPage";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import PageContent from "@/components/PageContent.vue";
import store from "@/store";
import {
  FetchLandingPageListResponse,
  FetchLandingPageNameListResponse,
} from "@/store/modules/landingPage";
import { Season } from "@/models/Season";
import { CampaignName } from "@/models/Campaign";
import {
  FetchSeasonListResponse,
  FetchSeasonNameListResponse,
} from "@/store/modules/season";
import { SeasonName } from "@/models/Season";
import CreateLandingPageDialog from "./_components/CreateLandingPageDialog.vue";
import UpdateLandingPageDialog from "./_components/UpdateLandingPageDialog.vue";
import SnippetDialog from './_components/SnippetDialog.vue'

import {
  FetchCampaignNameListParams,
  FetchCampaignNameListResponse,
} from "@/store/modules/campaign";

@Component({
  components: {
    PageContent,
    CreateLandingPageDialog,
    UpdateLandingPageDialog,
    SnippetDialog
  },
})
export default class LandingPageView extends Vue {
  showCreateDialog = false;
  showUpdateDialog = false;
  selectedLandingPage: LandingPage | null = null;
  campaignNameList: Array<CampaignName> = Array(0);

  get pageTitle(): string {
    return "LP管理画面";
  }
  get tableHeader() {
    return [
      { text: "キャンペーンシーズン", value: "season_name", width: "auto" },
      { text: "LP名", value: "landing_page_name", width: "auto" },
      { text: "キャンペーン名", value: "campaign_name", width: "auto" },
      { text: "キャンペーンURL", value: "landing_page_url", width: "auto" },
      { text: "LP掲載開始日時", value: "lp_publish_start_at", width: "auto" },
      { text: "LP掲載終了日時", value: "lp_publish_end_at", width: "auto" },
      { text: "データ作成日時", value: "created_at", width: "auto" },
      { text: "", value: "showDetails", width: "auto" },
      { text: "", value: "showSnippet", width: "auto" },
    ];
  }

  landingPages: Array<LandingPage>;

  constructor() {
    super();
    this.landingPages = new Array(0);
  }

  public create() {
    this.showCreateDialog = true;
  }
  public onCancel() {
    this.showCreateDialog = false;
  }

  public showDetails(item: LandingPage) {
    this.selectedLandingPage = item;
    this.showUpdateDialog = true;
  }

  showSnippetDialog = false;
  public showSnippet(item: LandingPage) {
    this.selectedLandingPage = item;
    this.showSnippetDialog = true;
  }

  public onCloseSnippet(){
    this.selectedLandingPage = null;
    this.showSnippetDialog = false;
  }

  public onCancelUpdate() {
    this.selectedLandingPage = null;
    this.showUpdateDialog = false;
  }

  seasonNameList = new Array<SeasonName>();
  landingPageNameList = new Array<LandingPageName>();
  searchSeasonName: SeasonName | null = null;
  serachCampaignName: CampaignName | null = null;
  searchLandingPageName: LandingPageName | null = null;
  public async mounted() {
    await store
      .dispatch("season/fetchSeasonNameList")
      .then((result: FetchSeasonNameListResponse) => {
        this.seasonNameList = result.season_name_list;
      });
    await store
      .dispatch("campaign/fetchCampaignNameList", {})
      .then((result: FetchCampaignNameListResponse) => {
        this.campaignNameList = result.campaign_name_list;
      });

    await store
      .dispatch("landingPage/fetchLandingPageNameList", {})
      .then((result: FetchLandingPageNameListResponse) => {
        this.landingPageNameList = result.landing_page_name_list;
      });

    this.searchSeasonName =
      this.seasonNameList.find(
        (sn: SeasonName) => sn.season_id == store.getters["landingPage/seasonId"]
      ) ??
      this.seasonNameList.find((sn: SeasonName) => sn.is_current_season) ??
      null;
    this.serachCampaignName =
      this.campaignNameList.find(
        (sn: CampaignName) =>
          sn.campaign_id == store.getters["landingPage/campaignId"]
      ) ?? null;
    this.searchLandingPageName =
      this.landingPageNameList.find(
        (lpn: LandingPageName) =>
          lpn.landing_page_id == store.getters["landingPage/landingPageId"]
      ) ?? null;
    this.query();
  }

  
  @Watch("searchSeasonName")
  onCampaignTypeChanged(newSeason: Season, oldSeason: Season | null) {
    if (newSeason.season_id != oldSeason?.season_id) {
      this.serachCampaignName = null;
      this.searchLandingPageName = null;
    }
  }

  get seasonCampaign() {
    return this.campaignNameList.filter(
      (cn: CampaignName) => cn.season_id == this.searchSeasonName?.season_id
    );
  }

  get seasonLp(){
    return this.landingPageNameList.filter(
      (lpn: LandingPageName) => lpn.season_id == this.searchSeasonName?.season_id
    )
  }

  selectSeason(e: SeasonName){
    this.serachCampaignName = null;
    this.searchLandingPageName = null;
    this.searchSeasonName = e;


    this.query();
  }

  selectCampaign(e: CampaignName){
    this.serachCampaignName = e;
    this.query();
  }

  selectLandingPage(e: LandingPageName){
    this.searchLandingPageName = e;
    this.query();
  }

  async query() {
    if (!this.searchSeasonName) {
      this.searchSeasonName =
        this.seasonNameList.find((sn: SeasonName) => sn.is_current_season) ??
        null;
    }

    store
      .dispatch("landingPage/fetchLandingPageList", {
        season_id: this.searchSeasonName!.season_id,
        campaign_id: this.serachCampaignName?.campaign_id,
        landing_page_id: this.searchLandingPageName?.landing_page_id,
      })
      .then((result: FetchLandingPageListResponse) => {
        this.landingPages = result.landing_page_list;
      });
  }

  public onCreate() {
    this.showCreateDialog = false;
    this.query();
  }

  public onUpdate() {
    this.showUpdateDialog = false;
    this.query();
  }

  public onDelete() {
    this.showUpdateDialog = false;
    this.query();
  }
}
