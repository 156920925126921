import { Segment } from "./Segment"

export const enum ScheduleType{
  SOON = 0, //"即時",
  ONCE = 1,  //"1回",
  HOURLY = 2,  //"毎時間",
  DAYLY = 3, //"毎日",
  WEEKLY = 4, //"毎週",
  MONTHLY = 5, //"毎月" 
}

type NotificateOptions = {
  name?: string,
  segment?: Segment,
  title?: string,
  body?: string,
  schedule_type?: ScheduleType,
  start_date?: string,
  end_date?: string,
  last_notify?: string,
  created_at?: string,
  resend_available_flag?: boolean
}

export class Notification{
  name?: string;
  segment?: Segment;
  title?: string;
  body?: string;
  schedule_type?: ScheduleType;
  start_date?: string;
  end_date?: string;
  last_notify?: string;
  created_at?: string;
  resend_available_flag?: boolean;

  constructor(options: NotificateOptions){
    this.name = options.name;
    this.segment = options.segment;
    this.title = options.title;
    this.body = options.body;
    this.schedule_type = options.schedule_type;
    this.start_date = options.start_date;
    this.end_date = options.end_date;
    this.last_notify = options.last_notify;
    this.created_at = options.created_at;
    this.resend_available_flag = options.resend_available_flag;
  }
}