
import { SignInParams } from "@/store/modules/auth";
import store from "@/store";

import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class ConfirmForgetPasswordView extends Vue {
  get passwordRules() {
    return [
      (v: string) => v.length > 0 || "入力必須項目です",
      (v: string) =>
        /[a-z]+/.test(v) ||
        "a-z,A-Z,0-9,!,#,$,%,&,@,+,*,?から10文字以上指定してください",
      (v: string) =>
        /[A-Z]+/.test(v) ||
        "a-z,A-Z,0-9,!,#,$,%,&,@,+,*,?から10文字以上指定してください",
      (v: string) =>
        /[0-9]+/.test(v) ||
        "a-z,A-Z,0-9,!,#,$,%,&,@,+,*,?から10文字以上指定してください",
      (v: string) =>
        /[!#$%&@+*?]+/.test(v) ||
        "a-z,A-Z,0-9,!,#,$,%,&,@,+,*,?から10文字以上指定してください",
      (v: string) =>
        /^[a-zA-Z0-9!#$%&@+*?]{10,}$/.test(v) ||
        "a-z,A-Z,0-9,!,#,$,%,&,@,+,*,?から10文字以上指定してください",
    ];
  }

  get newPasswordRules() {
    return [
      (v: string) => !!v || "入力必須項目です",
      (v: string) => v === this.newPassword || "パスワードが一致しません",
    ];
  }

  get mailRules() {
    return [
      (v: string) => !!v || "入力必須項目です",
      (v: string) =>
        /.+@.+\..+/.test(v) || "有効なメールアドレスを入力してください",
    ];
  }
  passwordChanged = false;
  newPassword =  "";
  newPasswordValidate = "";
  confirm() {
    const ref = this.$refs.form as Vue & { validate: () => boolean };
    
    if (!ref) {
      return;
    }
    if (!ref.validate()) {
      return;
    }
    this.$store
      .dispatch("user/confirmForgotPassword", {
        new_password: this.newPassword,
        token: this.$route.query.token,
      })
      .then((data) => {
        data;
        this.passwordChanged = true;
      });
  }
  created() {
    // if (!this.$route.query.token) {
    //   this.$store.dispatch("globals/setNotFound", true);
    // } else {
    //   this.$store.dispatch("globals/setNotFound", false);
    // }
    //   this.$store.dispatch('message/destroy')
    //   // already logined
    //   if (this.$store.state.auth.token) {
    //     this.$router.push('/')
    //   }
    // },
    // watch: {
    //   token (newToken) {
    //     this.$router.push('/')
    //   }
  }
}
