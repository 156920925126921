  type options = {
    name?: string, 
    icon_name?: string,
    children?: Array<DrawerMenuElement>,
    to?: string,
    exact?: boolean
  }

export class DrawerMenuElement{
  name?: string;
  children?: Array<DrawerMenuElement>;
  icon_name?: string;
  to?: string;
  exact: boolean

  constructor(options: options){
    this.name = options.name;
    this.children = options.children;
    this.icon_name = options.icon_name;
    this.to = options.to;
    this.exact = options.exact ?? false
  }

  public hasChild(): boolean {
    if (!this.children) return false
    return (this.children?.length > 0) ?? false;
  }
}