import api from '@/services/api';
import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import store from '@/store'
import { RootState } from '@/store';

import { SignInResponse, RefreshResponse } from '@/models/ApiResponse';
import { GameState } from '../types';

import { Game, GameName } from '@/models/Game';

import { Utils } from '@/Utility';

const GameApiEndpoint = {
  fetchTableList : 'api/v1/games/list',
  fetchNameList : 'api/v1/games/name_list',
  createGame: 'api/v1/games',
  updateGame: 'api/v1/games',
  deleteGame: 'api/v1/games',
}



// チーム一覧を取得する際のパラメータ
export type FetchGameListParams = {
  // シーズンID
  season_id: string|null,
  away_team_id: string|null,
  start_date: string|null,
  end_date: string|null,
}

export type FetchGameNameListParams = {
  season_id: string|null;
}

export type PostGameParams = {
  season_id: string,
  away_team_id: string,
  game_at: string,
  alena_id: string
}

export type PutGameParams = {
  game_id: string,
  away_team_id: string,
  game_at: string,
  alena_id: string
}

export type DeleteGameParams = {
  game_id: string,
}


export type FetchGameListResponse = {
  game_list: Array<Game>
}
export type FetchGameNameListResponse = {
  game_name_list: Array<GameName>
}

const state: GameState = {
  searchSeasonId: null,
  searchTeamId: null,
  searchEndDate: null,
  searchStartDate: null,
}

export const GameActionTree: ActionTree<GameState, RootState> = {
  fetchGameList: async({ commit }, params: FetchGameListParams) => {
    return await api.get(GameApiEndpoint.fetchTableList, {
      params: Utils.trimValidData({
        season_id: params.season_id,
        away_team_id: params.away_team_id,
        start_date: params.start_date,
        end_date: params.end_date
      })
    }).then(res => {
      const fetchGameListResponse = res.data.data as FetchGameListResponse
      commit('saveSearchConditions', params)
      return fetchGameListResponse
    });
  },
  createGame: async({ commit }, params: PostGameParams) => {
    return await api.post(GameApiEndpoint.createGame, Utils.trimUndefined(params)
    ).then(res => {
      res;
      return;
    });
  },
  updateGame: async({ commit }, params: PutGameParams) => {
    return await api.put(GameApiEndpoint.updateGame, Utils.trimUndefined(params)
    ).then(res => {
      res;
      return;
    });
  },
  deleteGame: async({ commit }, params: DeleteGameParams) => {
    return await api.delete(GameApiEndpoint.deleteGame, {data:Utils.trimUndefined(params)}
    ).then(res => {
      res;
      return;
    });
  },
  fetchGameNameList: async({ commit }, params: FetchGameNameListParams) => {
    console.log(Utils.trimValidData({
    }))
    return await api.get(GameApiEndpoint.fetchNameList, {
      params: Utils.trimValidData(params)
    }).then(res => {
      console.log(res)
      return res.data.data as FetchGameNameListResponse
    });
  },
};

export const GameMutations: MutationTree<GameState> = {
  saveSearchConditions: (state, params: FetchGameListParams)=>{
    console.log(params)
    state.searchSeasonId = params.season_id;
    state.searchTeamId = params.away_team_id;
    state.searchStartDate = params.start_date;
    state.searchEndDate = params.end_date;
    // state.searchGameId = params.game_id;
    // localStorage.removeItem('token');
    // localStorage.removeItem('refresh');
  }
}

export const GameGetterTree: GetterTree<GameState, RootState> = {
  // gameId : state => state.searchGameId,
  seasonId: state => state.searchSeasonId,
  teamId: state => state.searchTeamId,
  startDate: state => state.searchStartDate,
  endDate: state => state.searchEndDate,
}

export const gameModule: Module<GameState, RootState> = {
  namespaced: true,
  state: state,
  getters: GameGetterTree,
  mutations: GameMutations,
  actions: GameActionTree
};