import api from '@/services/api';
import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import store from '@/store'
import { RootState } from '@/store';

import { SignInResponse, RefreshResponse } from '@/models/ApiResponse';
import { AlenaState } from '../types';

import { Alena, AlenaName } from '@/models/Alena';

import { Utils } from '@/Utility';

const AlenaApiEndpoint = {
  fetchTableList : 'api/v1/alenas/list',
  createAlena : 'api/v1/alenas',
  updateAlena : 'api/v1/alenas',
  deleteAlena : 'api/v1/alenas',
  fetchAlenaNameList: 'api/v1/alenas/name_list'
}

// チーム一覧を取得する際のパラメータ
export type FetchAlenaListParams = {
  // シーズンID
  alena_id: string | null
}

export type PostAlenaParams = {
  name: string,
  abb_name: string,
  zipcode: string,
  prefecture: string,
  city: string,
  address_1: string,
  tel: string,
  lat: string,
  lon: string
}

// アリーナを更新する際のパラメータ
export type PutAlenaParams = {
  alena_id: string,
  name: string,
  abb_name: string,
  zipcode: string,
  prefecture: string,
  city: string,
  address_1: string,
  tel: string,
  lat: string,
  lon: string
}

// アリーナを削除する際のパラメータ
export type DeleteAlenaParams = {
  alena_id: string,
}

// チーム一覧の返却
export type FetchAlenaListResponse = {
  alena_list: Array<Alena>
}

export type FetchAlenaNameListResponse = {
  alena_name_list: Array<AlenaName>
}

const state: AlenaState = {
  searchAlenaId: null
}

export const AlenaActionTree: ActionTree<AlenaState, RootState> = {
  fetchAlenaList: async({ commit }, params: FetchAlenaListParams) => {
    console.log(Utils.trimValidData({
      alena_id: params.alena_id
    }))
    return await api.get(AlenaApiEndpoint.fetchTableList, {
      params: Utils.trimValidData({
        alena_id: params.alena_id
      })
    }).then(res => {
      const fetchAlenaListResponse = res.data.data as FetchAlenaListResponse
      commit('saveSearchConditions', params)

      return fetchAlenaListResponse
    });
  },
  createAlena: async({ commit }, params: PostAlenaParams) => {
    return await api.post(AlenaApiEndpoint.createAlena, Utils.trimUndefined(params))
    .then(() => {
      return
    });
  },
  updateAlena: async({ commit }, params: PutAlenaParams) => {
    return await api.put(AlenaApiEndpoint.updateAlena, Utils.trimUndefined(params))
    .then(() => {
      return
    });
  },
  deleteAlena: async({ commit }, params: DeleteAlenaParams) => {
    return await api.delete(AlenaApiEndpoint.deleteAlena, {
      data: Utils.trimUndefined(params)
    })
    .then(() => {
      return
    });
  },
  fetchAlenaNameList: async({ commit }) => {
    console.log(Utils.trimValidData({
    }))
    return await api.get(AlenaApiEndpoint.fetchAlenaNameList, {
      params: Utils.trimValidData({
      })
    }).then(res => {
      return res.data.data as FetchAlenaNameListResponse;
    });
  },
};

export const AlenaMutations: MutationTree<AlenaState> = {
  saveSearchConditions: (state, params: FetchAlenaListParams)=>{
    state.searchAlenaId = params.alena_id;
    // state.searchAlenaId = params.alena_id;
    // localStorage.removeItem('token');
    // localStorage.removeItem('refresh');
  }
}

export const AlenaGetterTree: GetterTree<AlenaState, RootState> = {
  // alenaId : state => state.searchAlenaId,
  searchAlenaId: state => state.searchAlenaId
}

export const alenaModule: Module<AlenaState, RootState> = {
  namespaced: true,
  state: state,
  getters: AlenaGetterTree,
  mutations: AlenaMutations,
  actions: AlenaActionTree
};