
import { Component, Prop, Vue } from "vue-property-decorator";
import VCombobox from "vuetify";
import { Utils } from '@/Utility'
import store from '@/store'
import { PostLandingPageParams } from '@/store/modules/landingPage'
import { CampaignName } from "@/models/Campaign";
import { LandingPage } from "@/models/LandingPage";

@Component({
  components: {},
})
export default class CreateLandingPageDialog extends Vue {
  @Prop({ default: false })
  show!: boolean;

  @Prop({ default: 500 })
  width!: number;

  // @Prop({
  //   type: Object,
  //   default: undefined,
  //   required: true
  // })
  // details!: LandingPage
  @Prop({
    type: Array,
    default: Array<CampaignName>(0),
    required: true
  })
  campaignNameList!: Array<CampaignName>
  
  public name = "";
  public campaignName: CampaignName|null = null;
  public landingPageUrl = "";
  public publishStartAt = "";
  public publishEndAt = "";

  selectPublishStartAt = false;
  selectPublishEndAt = false;

  constructor(){
    super()
  }
  formatter(d: string) {
    return new Date(d).getDate();
  }



  get landingPageNameRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
    ]
  }
  
  get campaignNameRules(){
    return [
      (v: CampaignName) => v ? true: '入力必須項目です',
    ]
  }
  get landingPageUrlRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
    ]
  }
  get publishStartAtRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
    ]
  }
  get publishEndAtRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
    ]
  }


  get options() {
    return {
      animation: 200,
    };
  }

  commit() {
    const ref = (this.$refs.form as Vue & { validate: () => boolean });
    
    if(!ref){
      return;
    }
    if(!(ref.validate())){
      return;
    }


    const [st_year, st_month, st_day] = this.publishStartAt!.split("-");
    const [e_year, e_month, e_day] = this.publishEndAt!.split("-");

    store.dispatch('landingPage/createLandingPage', {
      landing_page_name: this.name,
      landing_page_url: this.landingPageUrl,
      campaign_id: this.campaignName!.campaign_id,
      lp_publish_start_at: `${st_year}/${st_month.padStart(2, '0')}/${st_day.padStart(2, '0')} 00:00:00`,
      lp_publish_end_at: `${e_year}/${e_month.padStart(2, '0')}/${e_day.padStart(2, '0')} 00:00:00`,
    } as PostLandingPageParams).then((result)=>{
      result;
      this.$emit("onCreate")
    })
  }
  mounted() {
    
    console.log("mounted")
  }
}
