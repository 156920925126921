
import { Component, Prop, Vue } from "vue-property-decorator";
import VCombobox from "vuetify";
import { Utils } from '@/Utility'
import store from '@/store'
import { PostTeamParams } from '@/store/modules/team'

class ConditionType {
  id: number;
  name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}

@Component({
  components: {},
})
export default class CreateTeamDialog extends Vue {
  @Prop({ default: false })
  show!: boolean;

  @Prop({ default: 500 })
  width!: number;

  
  public name: string;
  public abb_name: string;
  public club_id: string;
  public color_primary: string|null;
  public color_secondary: string|null;
  public icon_white_url: string;
  public icon_black_url: string;
  public website: string|null;

  constructor(){
    super()
    this.name = "";
    this.abb_name = "";
    this.club_id = "";
    this.color_primary = null;
    this.color_secondary = null;
    this.icon_white_url = ""
    this.icon_black_url = ""
    this.website = null;
  }

  get prefecture(){
    return Utils.prefectures();
  }

  get teamNameRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
    ]
  }
  
  get teamAbbNameRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
    ]
  }

  get clubIdRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
      (v: string) => /^\d+$/.test(v) || '7桁の数字で入力してください'
    ]
  }

  get colorRules(){
    return [
      (v: string) => !v || (/^#[\da-fA-F]{6}/.test(v) || "#aabbccのような形式で入力してください")
    ]
  }

  get iconUrlRules(){
    return [
      (v: string) => v?.length > 0 || '入力必須項目です',
      (v: string) => /^https?:\/\/.+\..+$/.test(v) || '正しいURLを入力してください'
    ]
  }

  get websiteRules(){
    return [
      (v: string) => !v || (/^https?:\/\/.+\..+$/.test(v) || '正しいURLを入力してください')
    ]
  }



  get options() {
    return {
      animation: 200,
    };
  }

  commit() {
    const ref = (this.$refs.form as Vue & { validate: () => boolean });
    console.log(ref)
    if(!ref){
      return;
    }
    if(!(ref.validate())){
      return;
    }

    store.dispatch('team/createTeam', {
      name: this.name,
      abb_name: this.abb_name,
      club_id: Number(this.club_id),
      color_primary: this.color_primary,
      color_secondary: this.color_secondary,
      icon_white_url: this.icon_white_url,
      icon_black_url: this.icon_black_url,
      website: this.website
    } as PostTeamParams).then((result)=>{
      result;
      this.$emit("onCreate")
    })
  }
  mounted() {
    console.log("mounted");
  }
}
