
import { Component, Prop, Vue } from "vue-property-decorator";
import { Utils } from "@/Utility";
import store from "@/store";
import { User } from '@/models/User'

@Component({
  components: {},
})
export default class UpdateUserDialog extends Vue {
  @Prop({ default: false })
  show!: boolean;

  @Prop({ default: 500 })
  width!: number;

  @Prop({
    type: Object,
    default: undefined,
    required: true
  })
  user!: User

  get passwordRules() {
    return [
      (v: string) => v.length > 0 || "入力必須項目です",
      (v: string) =>
        /[a-z]+/.test(v) ||
        "a-z,A-Z,0-9,!,#,$,%,&,@,+,*,?から10文字以上指定してください",
      (v: string) =>
        /[A-Z]+/.test(v) ||
        "a-z,A-Z,0-9,!,#,$,%,&,@,+,*,?から10文字以上指定してください",
      (v: string) =>
        /[0-9]+/.test(v) ||
        "a-z,A-Z,0-9,!,#,$,%,&,@,+,*,?から10文字以上指定してください",
      (v: string) =>
        /[!#$%&@+*?]+/.test(v) ||
        "a-z,A-Z,0-9,!,#,$,%,&,@,+,*,?から10文字以上指定してください",
      (v: string) =>
        /^[a-zA-Z0-9!#$%&@+*?]{10,}$/.test(v) ||
        "a-z,A-Z,0-9,!,#,$,%,&,@,+,*,?から10文字以上指定してください",
    ];
  }

  get newPasswordRules() {
    return [
      (v: string) => !!v || "入力必須項目です",
      (v: string) => v === this.newPassword || "パスワードが一致しません",
    ];
  }
  passwordChanged = false;
  newPassword = "";
  newPasswordValidate = "";


  constructor() {
    super();
  }

  close(){
    this.$emit('onPasswordUpdate')
  }
  commit() {
    const ref = this.$refs.form as Vue & { validate: () => boolean };
    
    if (!ref) {
      return;
    }
    if (!ref.validate()) {
      return;
    }
    this.$store
      .dispatch("user/changePassword", {
        new_password: this.newPassword,
        user_id: this.user!.user_id,
      })
      .then((data) => {
        data;
        this.passwordChanged = true;
      });
  }
}
