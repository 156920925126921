import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { authModule } from './modules/auth'
import { teamModule } from './modules/team'
import { alenaModule } from './modules/alena'
import { gameModule } from './modules/game'
import { seasonModule } from './modules/season'
import { campaignModule } from './modules/campaign'
import { campaignStatisticsModule } from './modules/campaignStatistics'
import { leadModule } from './modules/lead'
import { landingPageModule } from './modules/landingPage';
import { globalModule } from './modules/globals'
import { userModule } from './modules/users'

Vue.use(Vuex)

const rootState = {

}

export type RootState = typeof rootState;

export default new Vuex.Store({
  state: rootState,
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth: authModule,
    team: teamModule,
    alena: alenaModule,
    game: gameModule,
    season: seasonModule,
    campaign: campaignModule,
    campaignStatistics: campaignStatisticsModule,
    lead: leadModule,
    landingPage: landingPageModule,
    user: userModule,
    global: globalModule,
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage,
    })
  ]
})
