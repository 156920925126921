
import { ScheduleType, Notification } from "@/models/Notification";
import { Component, Prop, Vue } from "vue-property-decorator";
import PageContent from "@/components/PageContent.vue";

@Component({
  components: {
    PageContent,
  },
})
export default class notificationStatisticsView extends Vue {
  get pageTitle(): string {
    return "Push、メール統計";
  }

  get tableHeader() {
    return [
      { text: "配信日時", value: "published_at", width: "auto" },
      { text: "説明", value: "name", width: "auto" },
      { text: "配信種別", value: "type", width: "auto" },
      { text: "配信セグメント", value: "segment", width: "auto" },
      { text: "配信数", value: "notification_count", width: "auto" },
      { text: "開封数", value: "open", width: "auto" },
      { text: "開封率", value: "percentage", width: "auto" },
    ];
  }

  get campaigns(): Array<any> {
    let gs = [];

    for (let i = 1; i < 9; i++) {
      gs.push({
        published_at: "2022-01-23 " + "0" + i.toString() + ":12:34",
        name: "通知" + i.toString(),
        type: i % 2,
        segment:
          "202" +
          i.toString() +
          "-2" +
          (i + 1).toString() +
          "で応募回数が3回未満の人",
        notification_count: i * 100,
        open: (i * i * 100) % 100,
        percentage: Math.trunc((((i * i * 100) % 100) / (i * 100)) * 100),
      });
    }

    return gs;
  }
  public showDetails(item: Notification) {
    item;
  }

  public showSingle(item: any) {
    if (item.campaign_type != 0 ?? false) {
      return item.single.apply + "\r\n" + "(" + item.single.elect + ")";
    }
    return item.single.elect;
  }
  public showPair(item: any) {
    if (item.campaign_type != 0 ?? false) {
      return item.pair.apply + "\r\n" + "(" + item.pair.elect + ")";
    }
    return item.pair.elect;
  }
}
