
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import PageContent from "@/components/PageContent.vue";
import { Lead } from "@/models/Lead";
import { Season } from "@/models/Season";
import { FetchSeasonListResponse } from "@/store/modules/season";
import { CampaignName, CampaignHistory } from "@/models/Campaign";
import { Utils } from "@/Utility";
import EditLeadsInfoDialog from "./_components/EditLeadsInfoDialog.vue";
import CampaignApplicationHistoryDialog from "./_components/CampaignApplicationHistoryDialog.vue";

import store from "@/store";
import {
  FetchLeadListParams,
  FetchLeadListResponse,
  FetchCampaignAppliocationHistoryResponse,
} from "@/store/modules/lead";
import {
  FetchCampaignNameListParams,
  FetchCampaignNameListResponse,
} from "@/store/modules/campaign";
import CryptoJS from "crypto-js";

import { saveAs } from "file-saver";

@Component({
  components: {
    PageContent,
    EditLeadsInfoDialog,
    CampaignApplicationHistoryDialog,
  },
})
export default class CustomerView extends Vue {
  leads = Array<Lead>(0);
  lead_type: number | null = 2;
  selectDate = false;

  get pageTitle(): string {
    return "見込み顧客管理画面";
  }

  formatter = (d: string) => new Date(d).getDate();
  campaignHistory: Array<CampaignHistory> | null = null;
  showHistoryDialog = false;
  showHistory(item: Lead) {
    store
      .dispatch("lead/fetchApplicationHistory", {
        email: item.email,
      })
      .then((result: FetchCampaignAppliocationHistoryResponse) => {
        this.leadsDetails = item;
        this.campaignHistory = result.campaign_history_list;
        this.showHistoryDialog = true;
      });
  }

  updateForcedAccept() {
    this.leadsDetails = null;
    this.campaignHistory = null;
    this.showHistoryDialog = false;
    this.query();
  }

  cancelForcedAccept() {
    this.leadsDetails = null;
    this.campaignHistory = null;
    this.showHistoryDialog = false;
  }

  showLeadsDetails = false;
  leadsDetails: Lead | null = null;
  showDetails(item: Lead) {
    this.leadsDetails = item;
    this.showLeadsDetails = true;
  }

  onClose() {
    this.showLeadsDetails = false;
    this.leadsDetails = null;
  }

  onUpdate() {
    this.showLeadsDetails = false;
    this.leadsDetails = null;
    this.query();
  }

  /**
   * メアドの暗号化を解く。<br />
   * なお、AES-CBC#PKCS7で暗号化されているものとする。
   * @param word [string] 暗号化されたメールアドレス
   * @returns [string] 複合したメールアドレス
   */
  decrypt(word: string) {
    return Utils.decrypt(word);
    // const hex = CryptoJS.enc.Hex.parse(word);
    // const srcs = CryptoJS.enc.Base64.stringify(hex);

    // const key = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_AES_SECRET ?? "");
    // const iv = CryptoJS.enc.Hex.parse(process.env.VUE_APP_INIT_VECTOR ?? "");

    // const dec = CryptoJS.AES.decrypt(srcs, key, {
    //   iv: iv,
    //   mode: CryptoJS.mode.CBC,
    //   padding: CryptoJS.pad.Pkcs7,
    // });
    // const decStr = dec.toString(CryptoJS.enc.Utf8);
    // return decStr.toString().trim();
  }

  exportCsv() {
    store.dispatch("global/EnterApi");
    // const header = ["メールアドレス", "申込キャンペーン", "観戦試合"].join(",");

    // const body = this.leads.map((l: Lead) => {
    //   return [
    //     Utils.decrypt(l.email),
    //     l.application_campaign_name,
    //     l.game_name_for_display,
    //   ].join(",");
    // });

    // const now = new Date();
    // const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    // const content = [header].concat(body).join("\n");
    // const blob = new Blob([bom, content], { type: "text/csv" });

    // saveAs(
    //   blob,
    //   `見込み顧客一覧_${now.getFullYear()}${now.getMonth()}${now.getDay()}_${now.getHours()}${now.getMinutes()}${now.getSeconds()}.csv`
    // );

    store.dispatch("global/ExitApi");
  }

  get tableHeader() {
    return [
      { text: "登録シーズン", value: "register_season_name", width: "auto" },
      { text: "登録キャンペーン", value: "register_campaign_name", width: "auto" },
      { text: "顧客の種類", value: "customer_kind_for_display", width: "auto" },
      { text: "申込メールアドレス", value: "email", width: "auto" },
      {
        text: "メール送信可否",
        value: "is_send_mail_for_display",
        width: "auto",
      },
      { text: "ブラックリスト客", value: "is_blacklist", width: "auto" },
      { text: "強制当選", value: "is_forced_accept", width: "auto" },
      { text: "応募回数", value: "number_of_application", width: "auto" },
      { text: "当選回数", value: "number_of_winning", width: "auto" },
      { text: "来場回数", value: "number_of_visits", width: "auto" },
      { text: "購入回数", value: "number_of_ticket_purchases", width: "auto" },
      { text: "", value: "show_details", width: "auto" },
      { text: "", value: "show_history", width: "auto" },
    ];
  }

  seasonList: Array<Season> = Array(0);
  campaignNameList: Array<CampaignName> = Array(0);
  selectedRegisterSeason: Season | null = null;
  selectedCampaignSeason: Season | null = null;
  selectedCampaignName: CampaignName | null = null;
  searchEmail: string | null = null;
  searchApplicationNumsMin: number | null = null;
  searchApplicationNumsMax: number | null = null;
  searchWinningNumsMin: number | null = null;
  searchWinningNumsMax: number | null = null;
  searchVisitNumsMin: number | null = null;
  searchVisitNumsMax: number | null = null;
  searchPurchaseNumsMin: number | null = null;
  searchPurchaseNumsMax: number | null = null;
  searchCustomerKind: number | null = 3;
  searchNeverWonCampaign: number | null = null;
  searchGameDate: string | null = null;

  public mounted() {
    // this.selectedRegisterSeason = store.getters["lead/registerSeasonId"];
    // this.selectedCampaignSeason = store.getters["lead/campaignSeasonId"];
    this.searchEmail = store.getters["lead/email"];
    this.searchGameDate = store.getters["lead/gameAt"];
    this.searchApplicationNumsMin =
      store.getters["lead/numOfCampaignApplicationStart"];
    this.searchApplicationNumsMax =
      store.getters["lead/numOfCampaignApplicationEnd"];
    this.searchWinningNumsMin = store.getters["lead/numOfWinningStart"];
    this.searchWinningNumsMax = store.getters["lead/numOfWinningEnd"];
    this.searchVisitNumsMin = store.getters["lead/numOfVisitsStart"];
    this.searchVisitNumsMax = store.getters["lead/numOfVisitsEnd"];
    this.searchPurchaseNumsMin = store.getters["lead/numOfPurchasesStart"];
    this.searchPurchaseNumsMax = store.getters["lead/numOfPurchasesEnd"];
    this.searchCustomerKind = store.getters["lead/customerKind"] ?? "2";
    this.searchNeverWonCampaign = store.getters["lead/neverWonCampaign"];

    // まず、シーズン名一覧を取得する
    store
      .dispatch("season/fetchSeasonList")
      .then((season: FetchSeasonListResponse) => {
        //シーズン名一覧が取得できたら、次にキャンペーン名一覧を取得する

        this.seasonList = season.season_list;

        let srs = store.getters["lead/registerSeasonId"];
        // let scs = store.getters["lead/campaignSeasonId"];
        store
          .dispatch("campaign/fetchCampaignNameList", {})
          .then((campaign: FetchCampaignNameListResponse) => {
            this.campaignNameList = campaign.campaign_name_list;

            // いよいよ新規顧客の一覧を取得する
            // ただし、シーズンIDやキャンペーンIDはもしかしたら消えているかもしれないので、セットされている条件を確認する。
            // デフォルトは、カレントシーズンかつキャンペーン指定なし

            if (!srs) {
              this.selectedRegisterSeason = season.season_list.find(
                (s: Season) => s.is_current_season
              )!;
            } else {
              let _s = season.season_list.find(
                (s: Season) => s.season_id == srs
              );
              if (!_s) {
                // シーズン一覧に、最後に設定されていたシーズンが存在しない
                _s = season.season_list.find(
                  (s: Season) => s.is_current_season
                )!;
              }
              this.selectedRegisterSeason = _s!;
            }

            // if (!scs) {
            //   this.selectedCampaignSeason = season.season_list.find(
            //     (s: Season) => s.is_current_season
            //   )!;
            // } else {
            //   let _s = season.season_list.find(
            //     (s: Season) => s.season_id == scs
            //   );
            //   if (!_s) {
            //     // シーズン一覧に、最後に設定されていたシーズンが存在しない
            //     _s = season.season_list.find(
            //       (s: Season) => s.is_current_season
            //     )!;
            //   }
            //   this.selectedCampaignSeason = _s!;
            // }

            let campaignId = store.getters["lead/campaignId"];
            let _c = campaign.campaign_name_list.find(
              (c: CampaignName) => c.campaign_id == campaignId
            );
            if (!_c) {
              campaignId = null;
            }
            this.selectedCampaignName = _c ?? null;

            this.query();
          });
      });
  }

  public query() {
    store
      .dispatch("lead/fetchLeadList", {
        register_season_id: this.selectedRegisterSeason?.season_id,
        // campaign_season_id: this.selectedCampaignSeason?.season_id,
        campaign_id: this.selectedCampaignName?.campaign_id,
        email: this.searchEmail ? Utils.encrypt(this.searchEmail) : null,
        game_at: this.searchGameDate ?? null,
        number_of_campaign_application_start: String(
          this.searchApplicationNumsMin
        )
          ? this.searchApplicationNumsMin
          : null,
        number_of_campaign_application_end: String(
          this.searchApplicationNumsMax
        )
          ? this.searchApplicationNumsMax
          : null,
        number_of_winnings_start: String(this.searchWinningNumsMin)
          ? this.searchWinningNumsMin
          : null,
        number_of_winnings_end: String(this.searchWinningNumsMax)
          ? this.searchWinningNumsMax
          : null,
        number_of_ticket_purchases_start: String(this.searchPurchaseNumsMin)
          ? this.searchPurchaseNumsMin
          : null,
        number_of_ticket_purchases_end: String(this.searchPurchaseNumsMax)
          ? this.searchPurchaseNumsMax
          : null,
        number_of_visits_start: String(this.searchVisitNumsMin)
          ? this.searchVisitNumsMin
          : null,
        number_of_visits_end: String(this.searchVisitNumsMax)
          ? this.searchVisitNumsMax
          : null,
        customer_kind:
          this.searchCustomerKind?.toString() == "2"
            ? null
            : this.searchCustomerKind,
        never_won_campaign: Number(this.searchNeverWonCampaign),
      } as FetchLeadListParams)
      .then((result: FetchLeadListResponse) => {
        this.leads = result.customer_list;
      });
  }

  @Watch('selectedRegisterSeason')
  onCampaignTypeChanged(newSeason: Season, oldSeason: Season | null){
    if(newSeason.season_id != oldSeason?.season_id){
      this.selectedCampaignName = null;
    }
  }
  get seasonCampaign(){
    return this.campaignNameList.filter((cn: CampaignName)=>cn.season_id == this.selectedRegisterSeason?.season_id)
  }
}
