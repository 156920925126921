
import { Alena } from "@/models/Alena";
import { CampaignSummary, CampaignSummaryDetails, CampaignName } from "@/models/Campaign";
import {
  CampaignLotteryDetails,
  LotteryChangeInfo,
} from "@/models/CampaignLotteryDetails";
import { Component, Prop, Vue } from "vue-property-decorator";
import PageContent from "@/components/PageContent.vue";
import CreateCampaignDialog from "./_components/CreateCampaignDialog.vue";
import UpdateCampaignDialog from "./_components/UpdateCampaignDialog.vue";
import LotteryDetailsDialog from "./_components/LotteryDetailsDialog.vue";
import ChangeLotteryDialog from "./_components/ChangeLotteryDialog.vue";
import store from "@/store";
import {
  FetchCampaignSummaryListResponse,
  FetchCampaignSummaryDetailsParams,
  FetchCampaignSummaryDetailsResponse,
  FetchLotteryDetailsParams,
  FetchCampaignLotteryDetailsResponse,
  FetchChangeLotteryInfoParams,
  FetchCampaignNameListResponse,
} from "@/store/modules/campaign";
import CampaignQRCodeDialog from "./_components/CampaignQRCode.vue";
import {
  FetchSeasonListResponse,
  FetchSeasonNameListResponse,
} from "@/store/modules/season";
import { FetchGameNameListResponse } from "@/store/modules/game";
import { SeasonName } from "@/models/Season";
import { GameName } from "@/models/Game";
import QRCode from "qrcode";

@Component({
  components: {
    PageContent,
    CreateCampaignDialog,
    CampaignQRCodeDialog,
    LotteryDetailsDialog,
    UpdateCampaignDialog,
    ChangeLotteryDialog,
  },
})
export default class CampaignView extends Vue {
  campaigns = Array<CampaignSummary>(0);

  selectedCampaignsDetails?: CampaignSummaryDetails = undefined;
  showDetailsDialog = false;
  showCreateDialog = false;
  showLotteryDialog = false;
  showChangeLotteryDialog = false;

  selectDateStart = false;
  searchCampaignDateStart: string | null = null;
  selectDateEnd = false;
  searchCampaignDateEnd: string | null = null;

  showQrCode = false;
  qrCodeUri = "";
  qrCampaignCode = "";
  get pageTitle(): string {
    return "キャンペーン管理画面";
  }

  formatter = (d: string) => new Date(d).getDate();
  get tableHeader() {
    return [
      { text: "キャンペーンシーズン", value: "season_name", width: "auto" },
      { text: "キャンペーン名", value: "campaign_name", width: "auto" },
      { text: "キャンペーンコード", value: "campaign_code", width: "auto" },
      {
        text: "キャンペーンタイプ",
        value: "campaign_type_for_display",
        width: "auto",
      },
      {
        text: "キャンペーンの告知か発信",
        value: "campaign_announcement_type_for_display",
        width: "auto",
      },
      {
        text: "キャンペーン対象",
        value: "campaign_target_for_display",
        width: "auto",
      },
      { text: "イベント名称", value: "event_name", width: "auto" },
      { text: "エリア", value: "campaign_area", width: "auto" },
      {
        text: "キャンペーン開始日時",
        value: "campaign_start_at",
        width: "auto",
      },
      {
        text: "キャンペーン終了日時",
        value: "campaign_end_at",
        width: "auto",
      },
      { text: "一括抽選予定日", value: "lottery_on", width: "auto" },
      { text: "一括抽選実施日", value: "lottery_exec_on", width: "auto" },
      { text: "削除フラグ", value: "delete_flag_for_display", width: "auto" },
      {
        text: "キャンペーンの状態",
        value: "campaign_status_for_display",
        width: "auto",
      },
      { text: "試合名", value: "game_name_for_display", width: "auto" },
      {
        text: "1枚チケットの申込数と当選数\r\n申込数\r\n(当選数)",
        value: "one_ticket_status",
        width: "auto",
      },
      {
        text: "1枚チケットの枠数",
        value: "number_of_one_ticket",
        width: "auto",
      },
      {
        text: "1枚チケットの当選確率",
        value: "winning_rate_for_one_ticket",
        width: "auto",
      },
      {
        text: "2枚ペアチケットの申込数と当選数\r\n申込数\r\n(当選数)",
        value: "two_pair_ticket_status",
        width: "auto",
      },
      {
        text: "2枚ペアチケットの枠数",
        value: "number_of_two_pair_tickets",
        width: "auto",
      },
      {
        text: "2枚ペアチケットの\r\n当選確率",
        value: "winning_rate_for_two_pair_tickets",
        width: "auto",
      },
      { text: "データ作成日時", value: "created_at", width: "auto" },
      { text: "", value: "showDetails", width: "auto" },
      { text: "", value: "generateQR", width: "auto" },
      { text: "", value: "doLottery", width: "auto" },
    ];
  }

  seasonNameList = new Array<SeasonName>();
  gameNameList = new Array<GameName>();
  campaignNameList = new Array<CampaignName>();

  selectedCampaign: CampaignSummary | null = null;
  campaignLotteryDetails: Array<CampaignLotteryDetails> = Array(0);
  public preLottery(item: CampaignSummary) {
    store
      .dispatch("campaign/fetchLotteryDetails", {
        campaign_id: item.campaign_id,
      } as FetchLotteryDetailsParams)
      .then((details: FetchCampaignLotteryDetailsResponse) => {
        this.campaignLotteryDetails = details.lottery_campaign_details_list;
        this.selectedCampaign = item;
        this.showLotteryDialog = true;
      });
  }

  lotteryChangeInfo: LotteryChangeInfo | null = null;
  selectedCampaignSeason: SeasonName | null = null;
  public onChangeLottery() {
    store
      .dispatch("campaign/fetchChangeLotteryInfo", {
        campaign_id: this.selectedCampaign!.campaign_id,
      } as FetchChangeLotteryInfoParams)
      .then((lotteryChangeInfo: LotteryChangeInfo) => {
        this.selectedCampaignSeason = this.seasonNameList.find(
          (sn) => sn.season_id == this.selectedCampaign!.season_id
        )!;
        this.showChangeLotteryDialog = true;
        this.lotteryChangeInfo = lotteryChangeInfo;
      });
  }

  public onCancelChangeLottery() {
    this.showChangeLotteryDialog = false;
    this.lotteryChangeInfo = null;
  }

  public onChangeLotteryInfo() {
    store
      .dispatch("campaign/fetchLotteryDetails", {
        campaign_id: this.selectedCampaign!.campaign_id,
      } as FetchLotteryDetailsParams)
      .then((details: FetchCampaignLotteryDetailsResponse) => {
        this.campaignLotteryDetails = details.lottery_campaign_details_list;
        this.showChangeLotteryDialog = false;
        this.lotteryChangeInfo = null;
      });
  }

  public showDetails(item: CampaignSummary) {
    store
      .dispatch("campaign/fetchCampaignSummaryDetails", {
        campaign_id: item.campaign_id,
      } as FetchCampaignSummaryDetailsParams)
      .then((details: FetchCampaignSummaryDetailsResponse) => {
        this.showDetailsDialog = true;
        this.selectedCampaignsDetails = details;
      });
  }

  public onCloseDetails() {
    this.showDetailsDialog = false;
    this.selectedCampaignsDetails = undefined;
  }

  public onUpdate() {
    this.showDetailsDialog = false;
    this.selectedCampaignsDetails = undefined;

    store
      .dispatch("season/fetchSeasonList")
      .then((season: FetchSeasonListResponse) => {
        store
          .dispatch("campaign/fetchCampaignSummaryList", {
            season_id: season.season_list.find((s) => s.is_current_season)
              ?.season_id,
          })
          .then((result: FetchCampaignSummaryListResponse) => {
            this.campaigns = result.campaign_summaries_list;
          });
      });
  }

  public onCancelLottery() {
    this.showLotteryDialog = false;
    this.selectedCampaign = null;
    this.campaignLotteryDetails = new Array(0);
  }

  public onLotteryComplete() {
    this.showLotteryDialog = false;
    this.selectedCampaign = null;
    this.campaignLotteryDetails = new Array(0);

    store
      .dispatch("season/fetchSeasonList")
      .then((season: FetchSeasonListResponse) => {
        store
          .dispatch("campaign/fetchCampaignSummaryList", {
            season_id: season.season_list.find((s) => s.is_current_season)
              ?.season_id,
          })
          .then((result: FetchCampaignSummaryListResponse) => {
            this.campaigns = result.campaign_summaries_list;
          });
      });
  }

  public create() {
    this.showCreateDialog = true;
  }
  public onCancel() {
    this.showCreateDialog = false;
  }

  public onCreate() {
    this.showCreateDialog = false;

    store
      .dispatch("season/fetchSeasonList")
      .then((season: FetchSeasonListResponse) => {
        store
          .dispatch("campaign/fetchCampaignSummaryList", {
            season_id: season.season_list.find((s) => s.is_current_season)
              ?.season_id,
          })
          .then((result: FetchCampaignSummaryListResponse) => {
            this.campaigns = result.campaign_summaries_list;
          });
      });
  }

  onCancelQR() {
    this.showQrCode = false;
    this.qrCodeUri = "";
    this.qrCampaignCode = "";
  }
  async qrGen(campaignCode: string) {
    store.dispatch("global/EnterApi");

    QRCode.toDataURL(`format=BrexCampaign,code=${campaignCode}`, {
      errorCorrectionLevel: "H",
      type: "image/png",
      color: {
        dark: "#12315A",
        light: "#FFFFFF",
      },
      width: 512,
    })
      .then((url: any) => {
        this.qrCampaignCode = campaignCode;
        this.qrCodeUri = url;
        this.showQrCode = true;
        store.dispatch("global/ExitApi");
      })
      .catch((err: any) => {
        err;

        store.dispatch("global/ExitApi");
      });
  }
  public itemClass(e: any) {
    console.log(e);
  }

  searchSeasonName: SeasonName|null = null;
  serachCampaignName: CampaignName|null = null;
  public async mounted() {
    await store
      .dispatch("season/fetchSeasonNameList")
      .then((result: FetchSeasonNameListResponse) => {
        this.seasonNameList = result.season_name_list;
      });
    await store
      .dispatch("game/fetchGameNameList", {})
      .then((result: FetchGameNameListResponse) => {
        this.gameNameList = result.game_name_list;
      });

    await store
      .dispatch('campaign/fetchCampaignNameList', {})
      .then((result: FetchCampaignNameListResponse)=>{
        this.campaignNameList = result.campaign_name_list;
      });

    this.searchSeasonName = 
        this.seasonNameList.find((sn: SeasonName) => sn.season_id == store.getters['campaign/seasonId']) ?? 
        this.seasonNameList.find((sn: SeasonName) => sn.is_current_season) ?? 
        null;
    this.serachCampaignName = this.campaignNameList.find((sn: CampaignName) => sn.campaign_id == store.getters['campaign/campaignId']) ?? null;
    this.searchCampaignDateStart = store.getters['campaign/startDate']? store.getters['campaign/startDate'].replace(/\//g, '-'): null;
    this.searchCampaignDateEnd = store.getters['campaign/endDate']? store.getters['campaign/endDate'].replace(/\//g, '-') : null;

      this.query()
  }

  async query(){
    if(!this.searchSeasonName){
      this.searchSeasonName = this.seasonNameList.find((sn: SeasonName) => sn.is_current_season) ?? null;
    }

    store
      .dispatch("campaign/fetchCampaignSummaryList", {
        season_id: this.searchSeasonName?.season_id ?? null,
        campaign_id: this.serachCampaignName?.campaign_id,
        campaign_start_at: this.searchCampaignDateStart ? `${this.searchCampaignDateStart}`: null,
        campaign_end_at: this.searchCampaignDateEnd ? `${this.searchCampaignDateEnd}`: null,
      })
      .then((result: FetchCampaignSummaryListResponse) => {
        this.campaigns = result.campaign_summaries_list;
      });
  }
}
