
import { Season } from "@/models/Season";
import { Component, Prop, Vue } from "vue-property-decorator";
import PageContent from "@/components/PageContent.vue";
import store from '@/store';
import { FetchSeasonListResponse } from '@/store/modules/season';
import CreateSeasonDialog from './_components/CreateSeasonDialog.vue'
import UpdateSeasonDialog from './_components/UpdateSeasonDialog.vue'


@Component({
  components: {
    PageContent,
    CreateSeasonDialog,
    UpdateSeasonDialog,
  },
})
export default class SeasonView extends Vue {
  seasons = Array<Season>(0);

  get pageTitle(): string {
    return "シーズン管理";
  }

  get tableHeader() {
    return [
      { text: "デフォルト", value: "is_current_season", width: "auto" },
      { text: "シーズン", value: "season_name", width: "auto" },
      { text: "開始年", value: "season_year", width: "auto" },
      { text: "データ作成日時", value: "created_at", width: "auto" },
      { text: "", value: "showDetails", width: "auto" },
    ];
  }  
  
  public mounted(){
    store.dispatch('season/fetchSeasonList')
        .then((result: FetchSeasonListResponse)=>{
      
      this.seasons = result.season_list;
      
    })
  }
  
  showCreateSeasonDialog = false;
  onCancelCreate(){
    this.showCreateSeasonDialog = false;
  }

  onCreateSeason(){
    this.showCreateSeasonDialog = false;
    store.dispatch('season/fetchSeasonList')
        .then((result: FetchSeasonListResponse)=>{
      
      this.seasons = result.season_list;
      
    })
  }

  selectedSeason: Season|null = null;
  showDetailsDialog = false;
  public showDetails(item: Season) {
    this.showDetailsDialog = true;
    this.selectedSeason = item;
  }

  onCancelUpdate(){
    this.showDetailsDialog = false;
    this.selectedSeason = null;
  }

  onUpdateSeason(){
    this.showDetailsDialog = false;
    this.selectedSeason = null;

    store.dispatch('season/fetchSeasonList')
        .then((result: FetchSeasonListResponse)=>{
      
      this.seasons = result.season_list;
      
    })
  }

  onRemoveSeason(){
    this.showDetailsDialog = false;
    this.selectedSeason = null;

    store.dispatch('season/fetchSeasonList')
        .then((result: FetchSeasonListResponse)=>{
      
      this.seasons = result.season_list;
      
    })
  }
}
