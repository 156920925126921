
import { Component, Prop, Vue } from "vue-property-decorator";
import VCombobox from "vuetify";
import { Utils } from "@/Utility";
import { SeasonName } from "@/models/Season";
import { TeamName } from "@/models/Team";
import { AlenaName } from "@/models/Alena";
import store from "@/store";
import { PostGameParams } from "@/store/modules/game";

class ConditionType {
  id: number;
  name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}

@Component({
  components: {},
})
export default class CreateGameDialog extends Vue {
  @Prop({ default: false })
  show!: boolean;

  @Prop({ default: 500 })
  width!: number;

  @Prop({
    type: Array,
    default: Array(0),
    required: true,
  })
  seasons!: Array<SeasonName>;

  @Prop({
    type: Array,
    default: Array(0),
    required: true,
  })
  alenas!: Array<AlenaName>;

  @Prop({
    type: Array,
    default: Array(0),
    required: true,
  })
  teams!: Array<TeamName>;

  public alenaName: AlenaName | null;
  public teamName: TeamName | null;
  public seasonName: SeasonName | null;
  public gameDate: string | null;
  public selectDate: boolean;
  public gameTime: string | null;
  public selectTime: boolean;

  constructor() {
    super();
    this.gameDate = null;
    this.gameTime = null;
    this.alenaName = null;
    this.teamName = null;
    this.seasonName = null;
    this.selectDate = false;
    this.selectTime = false;
  }
  formatter(d: string) {
    return new Date(d).getDate();
  }

  allowedMinutes = (m: number) => m % 5 === 0;

  get alenaRules() {
    return [(v: AlenaName) => v ?? "入力必須項目です"];
  }

  get gameDateRules() {
    return [(v: string) => v?.length > 0 || "入力必須項目です"];
  }
  get gameTimeRules() {
    return [(v: string) => v?.length > 0 || "入力必須項目です"];
  }

  get teamRules() {
    return [(v: TeamName) => v ?? "入力必須項目です"];
  }

  get gameSeasonRules() {
    return [(v: SeasonName) => v ?? "入力必須項目です"];
  }

  commit() {
    const ref = this.$refs.form as Vue & { validate: () => boolean };
    console.log(ref);
    if (!ref) {
      return;
    }
    if (!ref.validate()) {
      return;
    }

    const [year, month, day] = this.gameDate!.split("-");
    store
      .dispatch("game/createGame", {
        season_id: this.seasonName!.season_id,
        away_team_id: this.teamName!.team_id,
        alena_id: this.alenaName!.alena_id,
        game_at: `${year}/${month.padStart(2, "0")}/${day.padStart(2, "0")} ${
          this.gameTime
        }:00`,
      } as PostGameParams)
      .then((res) => {
        res;
        this.$emit("onCreate");
      });
  }
  mounted() {
    console.log("mounted");
  }
}
