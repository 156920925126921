
import { Segment } from "@/models/Segment";
import { Component, Prop, Vue } from "vue-property-decorator";
import PageContent from "@/components/PageContent.vue";

@Component({
  components: {
    PageContent,
  },
})
export default class SegmentView extends Vue {

  get pageTitle(): string{
    return "セグメント管理画面";
  }
  get tableHeader() {
    return [
      { text: "申込シーズン", value: "season.name", width: "auto" },
      { text: "セグメント名", value: "name", width: "auto" },
      { text: "キャンペーン名", value: "campaign.name", width: "auto" },
      { text: "配信対象人数", value: "target_count", width: "auto" },
      { text: "応募回数", value: "apply_count", width: "auto" },
      { text: "当選回数", value: "elect_count", width: "auto" },
      { text: "来場回数", value: "visit_count", width: "auto" },
      { text: "購入回数", value: "bought_count", width: "auto" },
      { text: "試合日", value: "date", width: "auto" },
      { text: "キャンペーンに当選した事がない人のみ表示", value: "only_not_elected", width: "auto" },
      { text: "データ作成日時", value: "created_at", width: "auto" },
      { text: "", value: "action", width: "auto" },
    ];
  }

  get segments(): Array<Segment> {
    return []
  }
  public showDetails(item: Segment) {
    item
  }
}
