<template>
  <v-container fluid class="mx-0">
      <h3> {{ pageTitle }} </h3>
      <v-divider class="mb-3" />
      <v-row fluid v-if="showSearchPanel">
        <v-col :cols="12">
          <v-expansion-panels v-model="searchPanelStatus">
            <v-expansion-panel>
              <v-expansion-panel-header>
                {{
                  ( searchPanelStatus != 0 )
                    ? searchPanelTextWhenClosed
                    : searchPanelTextWhenOpened
                }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <slot name="searchPanelContent" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row fluid>
          <slot name="mainContent" />
      </v-row>
  </v-container>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class PageContent extends Vue {
  @Prop({
    type: String,
    default: ""
  })
  pageTitle

  @Prop({
    type: Boolean,
    default: true
  })
  showSearchPanel

  @Prop({
    type: String,
    default: "検索パネルを閉じる"
  })
  searchPanelTextWhenOpened

  @Prop({
    type: String,
    default: "検索パネルを開く"
  })
  searchPanelTextWhenClosed

  searchPanelStatus = 0
}
</script>

<style>

</style>