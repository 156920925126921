
import {  Team } from '@/models/Team'
import { Component, Prop, Vue } from "vue-property-decorator";
import PageContent from "@/components/PageContent.vue";
import store from '@/store';
import { FetchTeamListResponse } from '@/store/modules/team';
import CreateTeamDialog from './_components/CreateTeamDialog.vue';
import UpdateTeamDialog from './_components/UpdateTeamDialog.vue';

@Component({
  components: {
    PageContent,
    CreateTeamDialog,
    UpdateTeamDialog,
  },
})
export default class TeamView extends Vue {
  showCreateDialog = false;
  showUpdateDialog = false;
  selectedTeam: Team|null = null;

  teams: Array<Team>;

  public create() {
    this.showCreateDialog = true;
  }

  public onCancel(){
    this.showCreateDialog = false;
  }

  public onCreate(){
    this.showCreateDialog = false;

    store.dispatch('team/fetchTeamList',{
      team_id: null
    }).then((result: FetchTeamListResponse)=>{
      
      this.teams = result.team_list;
      
    })
  }

  get pageTitle(): string{
    return "チーム管理画面";
  }
  get tableHeader() {
    return [
      { text: "チーム名", value: "team_name", width: "auto" },
      { text: "略称", value: "abb_name", width: "auto" },
      { text: "ロゴ(白背景)", value: "icon_white_url", width: "auto" },
      { text: "ロゴ(黒背景)", value: "icon_black_url", width: "auto" },
      { text: "カラー(メイン)", value: "color_primary", width: "auto" },
      { text: "カラー(サブ)", value: "color_secondary", width: "auto" },
      { text: "Webサイト", value: "website", width: "auto" },
      { text: "データ作成日時", value: "created_at", width: "auto" },
      { text: "", value: "showDetails", width: "auto" },
    ];
  }


  constructor(){
    super();
    this.teams = new Array(0);
  }

  public showDetails(item: Team) {
    
    this.selectedTeam = item;
    this.showUpdateDialog = true;
  }

  public onCancelUpdate(){
    this.selectedTeam = null;
    this.showUpdateDialog = false;
  }
  public onUpdate(){
    this.selectedTeam = null;
    this.showUpdateDialog = false;
    
    store.dispatch('team/fetchTeamList',{
      team_id: null
    }).then((result: FetchTeamListResponse)=>{
      
      this.teams = result.team_list;
      
    })
  }

  public onDelete(){
    this.selectedTeam = null;
    this.showUpdateDialog = false;
    
    store.dispatch('team/fetchTeamList',{
      team_id: null
    }).then((result: FetchTeamListResponse)=>{
      
      this.teams = result.team_list;
      
    })
  }

  public mounted(){
    store.dispatch('team/fetchTeamList',{
      team_id: null
    }).then((result: FetchTeamListResponse)=>{
      
      this.teams = result.team_list;
      
    })
  }
}
