
import { GameName } from "@/models/Game";
import { Capacity } from "@/models/Capacity";
import { Component, Prop, Vue } from "vue-property-decorator";
import store from "@/store";
import { CampaignSummary } from "@/models/Campaign";
import { DoLotteryParams } from '@/store/modules/campaign'
import {
  CampaignLotteryDetails,
  LotteryDetailsWinningTarget,
} from "@/models/CampaignLotteryDetails";

import {
  WinningTarget,
  CampaignType,
  CampaignAnnouncementType,
  CampaignTarget,
} from "@/models/Campaign";

@Component({ components: {} })
export default class LotteryDetailsDialog extends Vue {
  @Prop({ default: false })
  show!: boolean;

  @Prop({ default: 500 })
  width!: number;

  @Prop({
    type: Array,
    default: Array<CampaignLotteryDetails>(0),
    required: true,
  })
  lotteryDetails!: Array<CampaignLotteryDetails>;

  @Prop({
    type: Object,
    default: undefined,
    required: true,
  })
  campaignSummary!: CampaignSummary;

  get winningTarget(): Array<WinningTarget> {
    return Array<WinningTarget>(
      { id: 1, name: "新規かつ当選回数が0回" },
      { id: 2, name: "新規かつ当選回数が1回以上" },
      { id: 3, name: "既存かつ当選回数が0回" },
      { id: 4, name: "既存かつ当選回数が1回以上" }
    );
  }

  get headers() {
    return [
      { text: "試合名", value: "game_name_for_display", width: "auto" },
      {
        text: "チケットの種類",
        value: "ticket_division_for_display",
        width: "auto",
      },
      { text: "枠数", value: "number_of_tickets", width: "auto" },
      {
        text: "チケットの種類毎の申込数",
        value: "number_of_applications",
        width: "auto",
      },
      { text: "新規かつ当選0の人", value: "is_target_1", width: "auto" },
      { text: "新規かつ当選1以上の人", value: "is_target_2", width: "auto" },
      { text: "既存かつ当選0の人", value: "is_target_3", width: "auto" },
      { text: "既存かつ当選1以上の人", value: "is_target_4", width: "auto" },
    ];
  }

  commit() {
    store
      .dispatch("campaign/doLottery", {
        campaign_id: this.campaignSummary.campaign_id
      } as DoLotteryParams)
      .then((result) => {
        result;
        this.$emit("onLotteryComplete");
      });
  }

  modify() {
    
    console.log("modify")
  }

  private __number_of_tickets(item: CampaignLotteryDetails, kind: number) {
    const wtl: Array<LotteryDetailsWinningTarget> = item.winning_target_list;
    
    
    return wtl.find((wtl: LotteryDetailsWinningTarget) => wtl.kind == kind)
      ?.number_of_winners;
  }

  private __priority() {
    const winningTarget = this.lotteryDetails[0].winning_target_list
      .filter((wtl: LotteryDetailsWinningTarget|null) => wtl?.priority != null);

    
    if (!winningTarget) return [];
    console.log(winningTarget)

    return winningTarget
      .sort((lhs: LotteryDetailsWinningTarget|null, rhs: LotteryDetailsWinningTarget|null) => lhs!.priority! - rhs!.priority!)
      .reduce((acc: Array<string>, wt: LotteryDetailsWinningTarget|null) => {
        acc.push(
          this.winningTarget.find((a: WinningTarget) => a.id == wt!.kind)!.name
        );
        return acc;
      }, new Array<string>(0));
  }

  mounted() {
    
    console.log("mounted")
  }
}
