import axios from 'axios';
import store from '@/store'
import router from '@/router';
import { ErrorInfo } from '@/models/ErrorInfo'
import { RefreshResponse } from '@/models/ApiResponse'
import CryptoJS from "crypto-js";

// const API_BASE = "http://localhost:8000/";
// const API_SERVER = "https://django.fusionedge-mockserver.com/"

// const API_BASE = "https://django.fusionedge-mockserver.com/";
// const API_SERVER = "https://django.fusionedge-mockserver.com/"


const API_BASE = "https://lead.utsunomiyabrex.net/";
const API_SERVER = "https://lead.utsunomiyabrex.net/"


const source = axios.CancelToken.source();
const params = {
  cancelToken: source.token,
  // baseURL: 'http://localhost:8000/',
  // baseURL: 'https://django.fusionedge-mockserver.com/',
  // baseURL: 'https://lead.utsunomiyabrex.net/',
  baseURL: API_BASE,
  headers: {
    'Content-Type': 'application/json',
  },
  validateStatus: (status: number) => status == 200 || status == 503
};

const api = axios.create(params);

api.interceptors.request.use(
  function (config) {
    store.dispatch('global/EnterApi')
    const a = new URLSearchParams(config.params)

    const endpoint = API_SERVER + config.url;
    const timestamp = Date.now();
    const method = config.method!.toUpperCase();
    const qp = new Map<string, any>();
    if(config.params){
      const usp = new URLSearchParams(config.params);
      for(const [key, value] of usp){
        
        qp.set(key, value);
      }
    }
    // const qp = config.params ? config.params as Map<string, any>: new Map<string, any>();

    const signature = caluclateSignature(method, endpoint, timestamp, qp);
  

    const token = store.getters['auth/token']
    const refresh = store.getters['auth/refresh']
    // let token = localStorage.getItem('token');
    // let refresh = localStorage.getItem('refresh');
      if(config.headers){
        config.headers.Signature = signature;
        config.headers.Timestamp = timestamp;
      }

    if (token) {
      if(config.headers){
        config.headers.Authorization = 'JWT ' + token
      }

      const __endpoint = API_SERVER + 'api/v1/system/refresh';
      const __timestamp = Date.now();
      const __method = 'POST';
      const __qp = new Map<string, any>();
      const __signature = caluclateSignature(__method, __endpoint, __timestamp, __qp);
      return axios
        .create(params)
        .post('api/v1/system/refresh', { 
          refresh: refresh 
        },
        {
          headers: {
            Timestamp: __timestamp,
            Signature: __signature,
          }
        })
        .then(res => {
          const refreshResponse = res.data.data as RefreshResponse
          store.dispatch('auth/updateLoggedIn', refreshResponse);
          // 認証用トークンをヘッダーに再セットする
          // token = localStorage.getItem('token');
          if(config.headers){
            config.headers.Authorization = 'JWT ' + refreshResponse.api_token;
            config.headers.Signature = signature;
            config.headers.Timestamp = timestamp;
          }

          return config;
        })
        .catch((error) => {
          // localStorage.removeItem('token');
          // localStorage.removeItem('refresh');
          return Promise.reject(error);
        });
    }
    return config
  },
  function (error) {
    store.dispatch('global/ExitApi')
    return Promise.reject(error);
  }
);

// 共通エラー処理
api.interceptors.response.use(
  function (response) {
    store.dispatch('global/ExitApi')
    return response;
  },
  function (error) {

    store.dispatch('global/ExitApi')
    // 
    const status = error.response ? error.response.status : 500;
    // let message = "";
    // エラーの内容に応じてstoreのメッセージを更新
    // let message;
    if(status == 503){
      return Promise.resolve(error.response)
    }else{
      if (status === 401) {
        store.dispatch('auth/signout');
        localStorage.removeItem('token');
        localStorage.removeItem('refresh');
        router.push({
          name: 'login'
        });
        // message = '再度、ログインしてください。';
        // store.dispatch('message/setErrorMessage', {
        //   message: message
        // });
      } else if (status === 403) {
        // 権限エラー
        // message = '権限エラーです。';
        // store.dispatch('message/setErrorMessage', {
        //   message: message
        // });
        store.dispatch('auth/signout');
        localStorage.removeItem('token');
        localStorage.removeItem('refresh');
        router.push({
          name: 'login'
        });
      } else {
        
        // その他のエラー
        // message = '想定外のエラーです。';
        // store.dispatch('message/setErrorMessage', {
        //   message: message
        // });
      }
    
      const message = error.response.data.error_info ? error.response.data.error_info.reduce((acc: Array<string>, e: ErrorInfo) => {
        if(e.user_message){
          acc.push(e.user_message);
        }
        return acc;
      }, []).join('<br />') : '想定外のエラーです。';
      
      store.dispatch('global/SetError', message);

      return Promise.reject(error);
      
    }
  }
);

const caluclateSignature = (method: string, endpoint: string, timestamp: number, params: Map<string, any>) =>{
  const __p = params;
  __p.set('timestamp', timestamp.toString());
  
  
  const paramString = Array.from(__p.keys()).reduce((acc: Array<string>, key: string)=>{
    
    for(const v of __p.get(key).split(',')){
      
      acc.push(key + '=' +v.toString());
    }

    return acc;
  }, []).join(',');
  
  
  
  

  const secretKey = 'BrexInvitationCampaignSystemClient'
  const payload = [
    method,
    paramString,
    endpoint
  ].join('&');
  
  const hmac = CryptoJS.HmacSHA256(payload, secretKey)
  
  return hmac.toString();
}

export default api;