import api from '@/services/api';
import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import store from '@/store'
import { RootState } from '@/store';

import { SignInResponse, RefreshResponse } from '@/models/ApiResponse';
import { AuthState } from '../types';

export type SignInParams = {
  email: string,
  password: string
}

const state: AuthState = {
  isLoggedIn: false,
  token: null,
  refresh: null
}

export const AuthActionTree: ActionTree<AuthState, RootState> = {
  updateLoggedIn:({ commit }, params: RefreshResponse) =>{
    commit('loggedIn', { 
      api_token: params.api_token, 
      refresh_token: null
    } as SignInResponse);
  },
  signin: async({ commit }, params: SignInParams) => {
    return await api.post('api/v1/system/signin', {
      email: params.email,
      password: params.password
    }).then(res => {
      const signInResponse = res.data.data as SignInResponse
      if(!signInResponse.api_token){
        
        throw ''
        return false
      }
      commit('loggedIn', signInResponse)
      return true;
    });
  },
  signout: async({ commit }) => {
    commit('logout')
  },
};

export const AuthMutations: MutationTree<AuthState> = {
  loggedIn: (state, data: SignInResponse)=> {
    state.isLoggedIn = true;
    api.defaults.headers.common['Authorization'] = `JWT ${data.api_token}`;
    state.token = data.api_token
    // localStorage.setItem('token', data.token);
    if(data.refresh_token){
      // localStorage.setItem('refresh', data.refresh);
      state.refresh = data.refresh_token
    }
  },
  logout: (state)=>{
    state.isLoggedIn = false
    state.refresh = null
    state.token = null
    // localStorage.removeItem('token');
    // localStorage.removeItem('refresh');
  }
}

export const AuthGetterTree: GetterTree<AuthState, RootState> = {
    isLoggedIn: state => state.isLoggedIn,
    refresh: state => state.refresh,
    token: state=>state.token
}

export const authModule: Module<AuthState, RootState> = {
  namespaced: true,
  state: state,
  getters: AuthGetterTree,
  mutations: AuthMutations,
  actions: AuthActionTree
};