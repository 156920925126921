import CryptoJS from "crypto-js";
export const Utils = {
  /**
   * undefinedとnullを削る
   * @param data 
   * @returns 
   */
  trimValidData(data: {
    [s: string]: unknown;
  } | ArrayLike<unknown>) {

    /*
    v が有効ならそのまま
    vが0, '', false, null, undefined, ....などfalseになるとき
      v ?? true ⇒ null, undefinedはtrueになる
     それ以外は二値化するとfalseになる
     その結果を反転するので、null, nudefined以外はフィールドに残る
    */
    return Object.fromEntries(Object.entries(data).filter(([k, v]) => { k; return v || !(v ?? true) }));
  },
  /**
   * undefinedとnull、空文字を削る
   * @param data 
   * @returns 
   */
  buildQuery(data: {
    [s: string]: unknown;
  } | ArrayLike<unknown>) {

    /*
    v が有効ならそのまま
    vが0, '', false, null, undefined, ....などfalseになるとき
      v ?? true ⇒ null, undefinedはtrueになる
     それ以外は二値化するとfalseになる
     その結果を反転するので、null, nudefined以外はフィールドに残る
    */
    return Object.fromEntries(Object.entries(data).filter(([k, v]) => { k; return v || !(v ?? true) }));
  },

  /**
   * remove Undefine from data
   * @param data 
   * @returns 
   */
  trimUndefined(data: {
    [s: string]: unknown;
  } | ArrayLike<unknown>) {

    /*
    v が有効ならそのまま
    vが0, '', false, null, undefined, ....などfalseになるとき
      v ?? true ⇒ null, undefinedはtrueになる
     それ以外は二値化するとfalseになる
     その結果を反転するので、null, nudefined以外はフィールドに残る
    */
    return Object.fromEntries(Object.entries(data).filter(([k, v]) => { k; return v || v !== undefined }));
  },

  prefectures(): Array<string>{
    return [
      '北海道',
      '青森県',
      '岩手県',
      '宮城県',
      '秋田県',
      '山形県',
      '福島県',
      '茨城県',
      '栃木県',
      '群馬県',
      '埼玉県',
      '千葉県',
      '東京都',
      '神奈川県',
      '新潟県',
      '富山県',
      '石川県',
      '福井県',
      '山梨県',
      '長野県',
      '岐阜県',
      '静岡県',
      '愛知県',
      '三重県',
      '滋賀県',
      '京都府',
      '大阪府',
      '兵庫県',
      '奈良県',
      '和歌山県',
      '鳥取県',
      '島根県',
      '岡山県',
      '広島県',
      '山口県',
      '徳島県',
      '香川県',
      '愛媛県',
      '高知県',
      '福岡県',
      '佐賀県',
      '長崎県',
      '熊本県',
      '宮崎県',
      '鹿児島県',
      '沖縄県'
    ]
  },

  between(number: number, min: number, max: number): boolean{
    if (number > max) return false
    if (number < min) return false

    return true
  },
  
  /**
   * メアドの暗号化を解く。<br />
   * なお、AES-CBC#PKCS7で暗号化されているものとする。
   * @param word [string] 暗号化されたメールアドレス
   * @returns [string] 複合したメールアドレス
   */
   decrypt(word: string) {
    const hex = CryptoJS.enc.Hex.parse(word);
    const srcs = CryptoJS.enc.Base64.stringify(hex);

    const key = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_AES_SECRET ?? "");
    const iv = CryptoJS.enc.Hex.parse(process.env.VUE_APP_INIT_VECTOR ?? "");

    const dec = CryptoJS.AES.decrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    const decStr = dec.toString(CryptoJS.enc.Utf8);
    return decStr.toString().trim();
  },
  
  /**
   * メアドを暗号化する。<br />
   * なお、AES-CBC#PKCS7で暗号化するものとする。
   * @param word [string] 平文のメールアドレス
   * @returns [string] 暗号化したメールアドレス
   */
   encrypt(word: string) {
    // const hex = CryptoJS.enc.Hex.parse(word);
    // const srcs = CryptoJS.enc.Base64.stringify(hex);
    const key = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_AES_SECRET ?? "");
    const iv = CryptoJS.enc.Hex.parse(process.env.VUE_APP_INIT_VECTOR ?? "");

    const enc = CryptoJS.AES.encrypt(word, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return enc.toString(CryptoJS.format.Hex);
  }
}