
import { Component, Prop, Vue } from "vue-property-decorator";
import { Utils } from "@/Utility";
import store from "@/store";
import { User } from '@/models/User'

@Component({
  components: {},
})
export default class UpdateUserDialog extends Vue {
  @Prop({ default: false })
  show!: boolean;

  @Prop({ default: 500 })
  width!: number;

  @Prop({
    type: Object,
    default: undefined, 
    required: true
  })
  details!: User

  get userStatus(){
    return [
      {
        value: false,
        text: "無効"
      },
      {
        value: true,
        text: "有効"
      }
    ]
  }

  public lastName = "";
  public firstName = "";
  public userName = "";
  public email = "";
  public isActive = true;

  constructor() {
    super();
  }

  formatter(d: string) {
    return new Date(d).getDate();
  }

  allowedMinutes = (m: number) => m % 5 === 0;

  get lastNameRules() {
    return [(v: string) => v.length > 0 || "入力必須項目です"];
  }
  get firstNameRules() {
    return [(v: string) => v.length > 0 || "入力必須項目です"];
  }
  get userNameRules() {
    return [(v: string) => v.length > 0 || "入力必須項目です"];
  }
  get emailRules() {
    return [(v: string) => v.length > 0 || "入力必須項目です"];
  }
  get accountStatusRules() {
    return [(v?: boolean) => v != null ||  "入力必須項目です"];
  }
  get passwordRules() {
    return [(v: string) => v.length > 0 || "入力必須項目です",
            (v: string) => /[a-z]+/.test(v) || "a-z,A-Z,0-9,!,#,$,%,&,@,+,*,?から10文字以上指定してください",
            (v: string) => /[A-Z]+/.test(v) || "a-z,A-Z,0-9,!,#,$,%,&,@,+,*,?から10文字以上指定してください",
            (v: string) => /[0-9]+/.test(v) || "a-z,A-Z,0-9,!,#,$,%,&,@,+,*,?から10文字以上指定してください",
            (v: string) => /[!#$%&@+*?]+/.test(v) || "a-z,A-Z,0-9,!,#,$,%,&,@,+,*,?から10文字以上指定してください",
            (v: string) => /^[a-zA-Z0-9!#$%&@+*?]{10,}$/.test(v) || "a-z,A-Z,0-9,!,#,$,%,&,@,+,*,?から10文字以上指定してください"];
  }

  commit() {
    const ref = this.$refs.form as Vue & { validate: () => boolean };
    
    if (!ref) {
      return;
    }
    if (!ref.validate()) {
      return;
    }
    store.dispatch('user/updateUser',{
      user_id: this.details.user_id,
      username: this.userName,
      last_name: this.lastName,
      first_name: this.firstName,
      is_active: this.isActive
    }).then((result)=>{
      result
      this.$emit('onUpdate')
    })
  }
  mounted() {
    
    this.userName = this.details.username;
    this.lastName = this.details.last_name;
    this.firstName = this.details.first_name;
    this.email = this.details.email;
    this.isActive = this.details.is_active
  }
}
