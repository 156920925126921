import api from '@/services/api';
import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import store from '@/store'
import { RootState } from '@/store';

import { SignInResponse, RefreshResponse } from '@/models/ApiResponse';
import { LeadState } from '../types';

import { Lead } from '@/models/Lead';
import { CampaignHistory } from '@/models/Campaign';

import { Utils } from '@/Utility';

const LeadApiEndpoint = {
  fetchTableList : 'api/v1/leads/list',
  customerBulkInsert: 'api/v1/fc_member/csv',
  updateProfile: 'api/v1/leads',
  fetchApplicationHistory: 'api/v1/leads/{:email:}/campaigns/history',
  forcedAccept: 'api/v1/leads/campaigns/forced_accept',
}



// チーム一覧を取得する際のパラメータ
export type FetchLeadListParams = {
  register_season_id: string|null;
  // campaign_season_id: string|null;
  campaign_id: string|null;
  email: string|null;
  game_at: string|null;
  number_of_campaign_application_start: number|null;
  number_of_campaign_application_end: number|null;
  number_of_winnings_start: number|null;
  number_of_winnings_end: number|null;
  number_of_ticket_purchases_start: number|null;
  number_of_ticket_purchases_end: number|null;
  number_of_visits_start: number|null;
  number_of_visits_end: number|null;
  customer_kind: number|null;
  never_won_campaign: number|null;
}

export type UpdateProfileParams = {
  email: string,
  is_black_list: boolean,
  is_send_mail: boolean
}

export type ForcedAcceptCampaign = {
  campaign_id: string,
  is_forced_accept: boolean,
}
export type ForcedAcceptParams = {
  email: string,
  campaign_list: Array<ForcedAcceptCampaign>
}

export type FetchCampaignApplicationHistoryParams = {
  email: string
}

export type FetchCampaignAppliocationHistoryResponse = {
  campaign_history_list: Array<CampaignHistory>
}

// チーム一覧を取得する際のパラメータ
export type CustomerBulkInsertParams = {
  csv_data: Array<string>
}

// チーム一覧の返却
export type FetchLeadListResponse = {
  customer_list: Array<Lead>
}

const state: LeadState = {
  searchCampaignSeasonId: null,
  searchRegisterSeasonId: null,
  searchEmail: null,
  searchGameAt: null,
  searchCampaignId: null,
  searchNumberOfCampaignApplicationStart: null,
  searchNumberOfCampaignApplicationEnd: null,
  searchNumberOfTicketPurchasesStart: null,
  searchNumberOfTicketPurchaseEnd: null,
  searchNumberOfVisitsStart: null,
  searchNumberOfVisitsEnd: null,
  searchNumberOfWinningStart: null,
  searchNumberOfWinningEnd: null,
  customerKind: null,
  neverWonCampaign: null
}

export const LeadActionTree: ActionTree<LeadState, RootState> = {
  fetchLeadList: async({ commit }, params: FetchLeadListParams) => {
    return await api.get(LeadApiEndpoint.fetchTableList, {
      params: Utils.trimValidData(
        params
      )
    }).then(res => {
      const fetchLeadListResponse = res.data.data as FetchLeadListResponse
      commit('saveSearchConditions', params)
      return fetchLeadListResponse
    });
  },
  fetchApplicationHistory: async({ commit }, params: FetchCampaignApplicationHistoryParams) => {
    return await api.get(LeadApiEndpoint.fetchApplicationHistory.replace('{:email:}', params.email), {
      params: Utils.trimValidData(
        params
      )
    }).then(res => {
      return res.data.data as FetchCampaignAppliocationHistoryResponse
    });
  },
  customerBulkInsert: async({ commit }, params: CustomerBulkInsertParams) => {
    return await api.post(LeadApiEndpoint.customerBulkInsert, Utils.trimValidData(params))
      .then(res => {
        res;
        return true;
      });
  },
  updateProfile: async({ commit }, params: UpdateProfileParams) => {
    return await api.put(LeadApiEndpoint.updateProfile, Utils.trimValidData(params))
      .then(res => {
        res;
        return true;
      });
  },
  forcedAccept: async({ commit }, params: ForcedAcceptParams) =>{
    return await api.put(LeadApiEndpoint.forcedAccept, Utils.trimValidData(params))
      .then(res => {
        res;
        return true;
      });
  }
};

export const LeadMutations: MutationTree<LeadState> = {
  saveSearchConditions: (state, params: FetchLeadListParams)=>{
    // state.searchCampaignSeasonId = params.campaign_season_id,
    state.searchRegisterSeasonId = params.register_season_id,
    state.searchCampaignId = params.campaign_id,
    state.searchEmail = params.email,
    state.searchNumberOfCampaignApplicationStart = params.number_of_campaign_application_start,
    state.searchNumberOfCampaignApplicationEnd = params.number_of_campaign_application_end,
    state.searchNumberOfTicketPurchasesStart = params.number_of_ticket_purchases_start,
    state.searchNumberOfTicketPurchaseEnd = params.number_of_ticket_purchases_end,
    state.searchNumberOfVisitsStart = params.number_of_visits_start,
    state.searchNumberOfVisitsEnd = params.number_of_visits_end,
    state.searchNumberOfWinningStart = params.number_of_winnings_start,
    state.searchNumberOfWinningEnd = params.number_of_winnings_end,
    state.customerKind = params.customer_kind,
    state.neverWonCampaign = params.never_won_campaign
  }
}

export const LeadGetterTree: GetterTree<LeadState, RootState> = {
  // leadId : state => state.searchLeadId,
  // campaignSeasonId: state => state.searchCampaignSeasonId,
  registerSeasonId: state => state.searchRegisterSeasonId,
  campaignId: state => state.searchCampaignId,
  email: state => state.searchEmail ? Utils.decrypt(state.searchEmail) : null,
  numOfCampaignApplicationStart: state => state.searchNumberOfCampaignApplicationStart,
  numOfCampaignApplicationEnd: state => state.searchNumberOfCampaignApplicationEnd,
  numOfTicketPurchasesStart: state => state.searchNumberOfTicketPurchasesStart,
  numOfTicketPurchasesEnd: state => state.searchNumberOfTicketPurchaseEnd,
  numOfVisitsStart: state => state.searchNumberOfVisitsStart,
  numOfVisitsEnd: state => state.searchNumberOfVisitsEnd,
  numOfWinningStart: state => state.searchNumberOfWinningStart,
  numOfWinningEnd: state => state.searchNumberOfWinningEnd,
  customerKind: state => state.customerKind,
  neverWonCampaign: state => state.neverWonCampaign,
  gameAt: state => state.searchGameAt,
}

export const leadModule: Module<LeadState, RootState> = {
  namespaced: true,
  state: state,
  getters: LeadGetterTree,
  mutations: LeadMutations,
  actions: LeadActionTree
};