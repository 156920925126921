
import { GameName } from "@/models/Game";
import { Capacity } from "@/models/Capacity";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ components: {} })
export default class GameCapacityRow extends Vue {
  @Prop({
    type: Array,
    default: Array<GameName>(0),
    required: true,
  })
  games!: Array<GameName>;

  @Prop({
    type: Object,
    default: null,
    required: true,
  })
  modelValue!: Capacity;

  @Prop({
    type: Number,
    default: 1,
    required: true
  })
  campaignType!: number
  @Prop({
    type: Boolean,
    default: true,
    required: true
  })
  editable!: boolean

  selectedGame: GameName|null = null;
  single = 0;
  pair = 0;

  get singleLabel(){
    if(this.campaignType == 2){
      return "1枚チケットの当選率"
    }else{
      return "1枚のチケット枚数"
    }
  }
  get pairLabel(){
    if(this.campaignType == 2){
      return "2枚チケットの当選率"
    }else{
      return "2枚のチケット枚数"
    }
  }
  get required(){
    return [
      (v: string) => v?? '入力必須項目です',
    ]
  }

  onInputSingle(e: number) {
    let m = this.modelValue;
    m.single = Number(e);
    this.$emit("update:modelValue", m);
  }
  onInputPair(e: number) {
    let m = this.modelValue;
    m.pair = Number(e);
    this.$emit("update:modelValue", m);
  }
  onInputGame(e: GameName) {
    let m = this.modelValue;
    m.game = e;
    this.$emit("update:modelValue", m);
  }
}
