import api from '@/services/api';
import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import store from '@/store'
import { RootState } from '@/store';

import { SignInResponse, RefreshResponse } from '@/models/ApiResponse';
import { CampaignState } from '../types';

import { CampaignSummary, CampaignName, CampaignSummaryDetails } from '@/models/Campaign';
import { CampaignLotteryDetails, LotteryChangeInfo } from '@/models/CampaignLotteryDetails'

import { Utils } from '@/Utility';

const CampaignApiEndpoint = {
  fetchSummaryTableList : 'api/v1/campaigns/summary/list',
  fetchSummaryDetails : 'api/v1/campaigns/summary/details',
  createCampaign: 'api/v1/campaigns',
  updateCampaign: 'api/v1/campaigns',
  fetchLotteryDetails: 'api/v1/campaigns/lottery_details',
  fetchNameList : 'api/v1/campaigns/name_list',
  doLottery : 'api/v1/campaigns/lottery',
  fetchLotteryChangeInfo: 'api/v1/campaigns/lottery_change_info',
  changeLotteryInfo: 'api/v1/campaigns/lottery',
  fetchStatistics: 'api/v1/campaigns/statistics',
  fetchWinnersList: 'api/v1/campaigns/winner_list'
}



// チーム一覧を取得する際のパラメータ
export type FetchCampaignSummaryListParams = {
  // シーズンID
  season_id: string|null,
  campaign_id: string|null,
  campaign_start_at: string|null,
  campaign_end_at: string|null,
}


export type FetchLotteryDetailsParams = {
  campaign_id: string
}

export type DoLotteryParams = {
  campaign_id: string
}

export type FetchWinnerListParams = {
  campaign_id: string,
  game_id: string,
}

export type CampaignGame = {
  game_id: string,
  number_of_one_ticket: number|null,
  number_of_two_pair_tickets: number|null,
  winning_rate_for_one_ticket: number|null,
  winning_rate_for_two_pair_tickets: number|null
}

export type PostCampaignParams = {
  season_id: string,
  campaign_name: string,
  campaign_code: string,
  campaign_type: number,
  campaign_announcement_type: number|null,
  campaign_target: number|null,
  campaign_area: string|null,
  event_name: string|null,
  lottery_on: string|null,
  lottery_reminder_send_mail_address_user_id: string|null,
  campaign_start_at: string,
  campaign_end_at: string,
  campaign_image_url: string,
  e_ticket_acquisition_url: string|null,
  winning_announcement_guidance: string,
  information_winners_guidance_for_app: string,
  information_winners_guidance_for_mail: string,
  information_loser_guidance_for_app: string,
  information_loser_guidance_for_mail: string,
  winning_target: string,
  game_list: Array<CampaignGame>
}



export type PutCampaignParams = {
  campaign_id: string,
  season_id: string,
  campaign_name: string,
  campaign_code: string,
  campaign_type: number,
  campaign_announcement_type: number|null,
  campaign_target: number|null,
  campaign_area: string|null,
  event_name: string|null,
  lottery_on: string|null,
  lottery_reminder_send_mail_address_user_id: string|null,
  campaign_start_at: string,
  campaign_end_at: string,
  campaign_image_url: string,
  e_ticket_acquisition_url: string|null,
  winning_announcement_guidance: string,
  information_winners_guidance_for_app: string,
  information_winners_guidance_for_mail: string,
  information_loser_guidance_for_app: string,
  information_loser_guidance_for_mail: string,
  winning_target: string,
  game_list: Array<CampaignGame>,
  delete_flag: boolean
}





// キャンペーン名一覧を取得するときのパラメータ
export type FetchCampaignNameListParams = {
  season_id: string|null;
}

// キャンペーン名一覧のレスポンス
export type FetchCampaignNameListResponse = {
  campaign_name_list: Array<CampaignName>
}

// サマライズされたキャンペーン一覧のレスポンス
export type FetchCampaignSummaryListResponse = {
  campaign_summaries_list: Array<CampaignSummary>
}

export type FetchWinnerListResponse = {
  csv_file_name: string,
  winner_list: Array<string>
}


// サマライズされたキャンペーンの詳細を取得するときのパラメータ
export type FetchCampaignSummaryDetailsParams = {
  campaign_id: string;
}
export type FetchCampaignSummaryDetailsResponse = CampaignSummaryDetails


// 一括抽選対象取得APIのレスポンス
export type FetchCampaignLotteryDetailsResponse = {
  lottery_campaign_details_list: Array<CampaignLotteryDetails>
}

export type FetchChangeLotteryInfoParams = {
  campaign_id: string
}


export type ChangeLotteryInfoGame = {
  game_id: string,
  number_of_tickets_per_ticket: number,
  number_of_pair_tickets: number,
}
export type ChangeLotteryInfoParams = {
  campaign_id: string,
  winning_target: string,
  game_list: Array<ChangeLotteryInfoGame>,
}


const state: CampaignState = {
  searchSeasonId: null,
  searchCampaignId: null,
  searchEndDate: null,
  searchStartDate: null,
}

export const CampaignActionTree: ActionTree<CampaignState, RootState> = {
  fetchCampaignSummaryList: async({ commit }, params: FetchCampaignSummaryListParams) => {
    return await api.get(CampaignApiEndpoint.fetchSummaryTableList, {
      params: Utils.trimValidData(params)
    }).then(res => {
      const fetchCampaignSummaryListResponse = res.data.data as FetchCampaignSummaryListResponse
      commit('saveSearchConditions', params)
      return fetchCampaignSummaryListResponse
    });
  },
  createCampaign: async({ commit }, params: PostCampaignParams) => {
    return await api.post(CampaignApiEndpoint.createCampaign, Utils.trimUndefined(params))
    .then(res => {
      res;
      return;
    });
  },
  doLottery: async({ commit }, params: DoLotteryParams) => {
    return await api.post(CampaignApiEndpoint.doLottery, Utils.trimUndefined(params))
    .then(res => {
      res;
      return;
    });
  },
  updateCampaign: async({ commit }, params: PutCampaignParams) => {
    return await api.put(CampaignApiEndpoint.updateCampaign, Utils.trimUndefined(params))
    .then(res => {
      res;
      return;
    });
  },
  fetchLotteryDetails: async({ commit }, params: FetchLotteryDetailsParams) => {
    return await api.get(CampaignApiEndpoint.fetchLotteryDetails, {
      params: Utils.trimValidData(params)
    })
    .then(res => {
      return res.data.data as FetchCampaignLotteryDetailsResponse
    });
  },
  fetchCampaignNameList: async({ commit }, params: FetchCampaignNameListParams) => {
    console.log(Utils.trimValidData({
    }))
    return await api.get(CampaignApiEndpoint.fetchNameList, {
      params: Utils.trimValidData(params)
    }).then(res => {
      console.log(res)
      return res.data.data as FetchCampaignNameListResponse
    });
  },
  fetchCampaignSummaryDetails: async({ commit }, params: FetchCampaignSummaryDetailsParams) => {
    console.log(Utils.trimValidData({
    }))
    return await api.get(CampaignApiEndpoint.fetchSummaryDetails, {
      params: Utils.trimValidData(params)
    }).then(res => {
      console.log(res)
      return res.data.data as FetchCampaignSummaryDetailsResponse
    });
  },
  fetchChangeLotteryInfo: async({ commit }, params: FetchChangeLotteryInfoParams) => {
    return await api.get(CampaignApiEndpoint.fetchLotteryChangeInfo, {
      params: Utils.trimValidData(params)
    }).then(res => {
      console.log(res)
      return res.data.data as LotteryChangeInfo
    });
  },
  changeLotteryInfo: async({ commit }, params: ChangeLotteryInfoParams) => {
    return await api.put(CampaignApiEndpoint.changeLotteryInfo, Utils.trimUndefined(params))
    .then(res => {
      res;
      return;
    });
  },
  fetchWinnerList: async({ commit }, params: FetchWinnerListParams) => {
    return await api.get(CampaignApiEndpoint.fetchWinnersList, {
      params: Utils.trimValidData(params)
    }).then(res => {
      console.log(res)
      return res.data.data as FetchWinnerListResponse
    });
  },
};

export const CampaignMutations: MutationTree<CampaignState> = {
  saveSearchConditions: (state, params: FetchCampaignSummaryListParams)=>{
    state.searchSeasonId = params.season_id;
    state.searchCampaignId = params.campaign_id;
    state.searchStartDate = params.campaign_start_at;
    state.searchEndDate = params.campaign_end_at;
    // state.searchCampaignSummaryId = params.campaignSummary_id;
    // localStorage.removeItem('token');
    // localStorage.removeItem('refresh');
  }
}

export const CampaignGetterTree: GetterTree<CampaignState, RootState> = {
  // campaignSummaryId : state => state.searchCampaignSummaryId,
  seasonId: state => state.searchSeasonId,
  campaignId: state => state.searchCampaignId,
  startDate: state => state.searchStartDate,
  endDate: state => state.searchEndDate,
}

export const campaignModule: Module<CampaignState, RootState> = {
  namespaced: true,
  state: state,
  getters: CampaignGetterTree,
  mutations: CampaignMutations,
  actions: CampaignActionTree
};