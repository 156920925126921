
import { Component, Prop, Vue } from "vue-property-decorator";
import { Utils } from "@/Utility";
import store from "@/store";
import { CreateSeasonParams } from '@/store/modules/season'


@Component({
  components: {},
})
export default class CreateSeasonDialog extends Vue {
  @Prop({ default: false })
  show!: boolean;

  @Prop({ default: 500 })
  width!: number;
  
  isCurrentSeason = false;
  seasonYear = ""
  
  get seasonName(){
    if(this.seasonYear.length != 4){
      return ''
    }
    if(!Number.isSafeInteger(Number(this.seasonYear))){
      return ''
    }
    return `${this.seasonYear}-${String((Number(this.seasonYear) + 1) % 100).padStart(2, '0')}`
  }


  formatter(d: string) {
    return new Date(d).getDate();
  }

  allowedMinutes = (m: number) => m % 5 === 0;

  get seasonYearRules() {
    return [
      (v: string) => v.length > 0 || "入力必須項目です",
      (v: string) => Number.isSafeInteger(Number(v)) || "数値で入力してください" ,
      (v: string) => v.length == 4 || "4桁で入力してください"
    ];
  }

  commit() {
    const ref = this.$refs.form as Vue & { validate: () => boolean };
    console.log(ref);
    if (!ref) {
      return;
    }
    if (!ref.validate()) {
      return;
    }
    store.dispatch('season/createSeason',{
      name: this.seasonName,
      year: Number(this.seasonYear),
      current: this.isCurrentSeason
    } as CreateSeasonParams).then((result)=>{
      result
      this.$emit('onCreate')
    })
  }
  mounted() {
    console.log("mounted");
  }
}
