import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import LeadView from '@/pages/lead/LeadView.vue'
import SegmentView from '@/pages/segment/SegmentView.vue'
import PushNotificationView from '@/pages/push_notification/PushNotificationView.vue'
import MailNotificationView from '@/pages/mail_notification/MailNotificationView.vue'
import CampaignStatisticsView from '@/pages/campaign_statistics/CampaignStatisticsView.vue'
import NotificationStatisticsView from '@/pages/nitification_statistics/NotificationStatisticsView.vue'
import SeasonView from '@/pages/season/SeasonView.vue'
import TeamView from '@/pages/team/TeamView.vue'
import AlenaView from '@/pages/alena/AlenaView.vue'
import LandingPageView from '@/pages/landing/LandingView.vue'
import GameView from '@/pages/game/GameView.vue'
import CampaignView from '@/pages/campaign/CampaignView.vue'
// import HomeView from '../views/HomeView.vue'
import LoginView from '@/pages/login/LoginView.vue'
import BulkInsertView from '@/pages/bulk/BulkView.vue'
import ManagerView from '@/pages/manager/ManagerView.vue'
import ForgetPasswordView from '@/pages/login/ForgetPasswordView.vue'
import ConfirmForgetPasswordView from '@/pages/login/ConfirmForgetPasswordView.vue'

import store from '@/store'
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/lead',
    name: 'lead',
    component: LeadView
  },
  {
    path: '/notification/segment',
    name: 'segment',
    component: SegmentView
  },
  {
    path: '/notification/push',
    name: 'push_notification',
    component: PushNotificationView
  },
  {
    path: '/notification/mail',
    name: 'mail_notification',
    component: MailNotificationView
  },
  {
    path: '/statistics/campaign',
    name: 'campaign_statistics',
    component: CampaignStatisticsView
  },
  {
    path: '/statistics/notification',
    name: 'notification_statistics',
    component: NotificationStatisticsView
  },
  {
    path: '/season/season',
    name: 'season',
    component: SeasonView
  },
  {
    path: '/game/team',
    name: 'team',
    component: TeamView
  },
  {
    path: '/game/alena',
    name: 'alena',
    component: AlenaView
  },
  {
    path: '/game/game',
    name: 'game',
    component: GameView
  },
  {
    path: '/initiatives/campaign',
    name: 'campaign',
    component: CampaignView
  },
  {
    path: '/initiatives/landing',
    name: 'landing',
    component: LandingPageView
  },
  {
    path: '/bulk',
    name: 'bulk',
    component: BulkInsertView
  },
  {
    path: '/user',
    name: 'user',
    component: ManagerView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { isPublic: true }
  },
  {
    path: '/forget_password',
    name: 'forget_password',
    component: ForgetPasswordView,
    meta: { isPublic: true }
  },
  {
    path: '/confirm_forget_password',
    name: 'confirm_forget_password',
    component: ConfirmForgetPasswordView,
    meta: { isPublic: true }
  },
  {
    path: '/*',
    name: 'login',
    component: LoginView,
    meta: { isPublic: true }
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // isPublic でない場合(=認証が必要な場合)、かつ、ログインしていない場合

  if (to.matched.some(record => !record.meta.isPublic) && !store.getters['auth/isLoggedIn']) {
    next({ name: 'login'});
  } else {
    next();
  }
});

export default router
